import { Route } from 'react-router-dom';
import { Helmet } from '../../components/Header/Helmet';
import LoginPage from './LoginPage/LoginPage';
import SignupPage from './SignupPage/SignupPage';

export default function AuthLayout() {
  return (
    <>
      <Helmet />

      <Route exact path="/" component={LoginPage} />
      <Route exact path="/signup" component={SignupPage} />
    </>
  );
}
