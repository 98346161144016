import styled from 'styled-components';
import { color } from '../../theme';

export const Link = styled.a`
  text-decoration: none;
  color: ${color.neutralDarker};
`;

interface CellProps {
  percentWidth?: number;
}

export const Cell = styled.div<CellProps>`
  display: flex;
  padding: 0 16px;
  align-items: center;
  font-family: Roboto;
  width: ${(props) => props.percentWidth || 5}%;
`;

export const HeaderRow = styled.div`
  display: flex;
  padding: 16px 0 16px 48px;
`;

export const Row = styled.div`
  display: flex;
  margin-left: 12px;
  width: 100%;
`;

export const ProductsTitle = styled.div`
  font-family: Lato;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 14px;
`;

interface ProductsRowProps {
  isHeaderRow?: boolean;
  isTotalRow?: boolean;
}

export const ProductsRow = styled.div<ProductsRowProps>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.isHeaderRow || props.isTotalRow ? 8 : 0)}px 8px;
  font-weight: ${(props) => (props.isTotalRow || props.isHeaderRow ? 700 : 400)};
  border-bottom: ${(props) => (props.isHeaderRow ? '1' : '0.1')}px solid
    ${(props) => (props.isHeaderRow ? color.muiDarkBorder : color.muiDarkBorderLighter)};
`;
