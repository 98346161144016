import { Box, Stepper } from '@material-ui/core';
import styled from 'styled-components';

export const SignupPageContainer = styled.div`
  width: 1200px;
  margin: 0 auto;
`;

export const TopBox = styled(Box)`
  margin: 30px 0 50px;
`;

export const StepperContainer = styled(Stepper)`
  margin: 30px 0 15px;
`;
