import { FC, useCallback } from 'react';
import { FieldsGrid, ScheduleBox } from './ScheduleForm.style';
import { TenantSectionProps, InputField, SectionTitle } from '../common';
import HoraryPicker from './HoraryPicker';
import { ShopInformations } from '../../../graphQl/api_generated';

type fieldsName = 'pickupDelayMinutes' | 'pickupTimeIncrementMinutes';

const pickupFields: {
  name: fieldsName;
  label: string;
  isRequired: boolean;
}[] = [
  { name: 'pickupDelayMinutes', label: 'Délai de retrait en minutes', isRequired: false },
  {
    name: 'pickupTimeIncrementMinutes',
    label: "Increment d'heure de livraison en minutes",
    isRequired: false,
  },
];

const ScheduleForm: FC<TenantSectionProps> = (props) => {
  const { shopDataState }: TenantSectionProps = props;

  const [shopData, setShopData] = shopDataState;

  const updateShopState = useCallback(
    (fieldName: fieldsName, newValue: number) => {
      const newShopData: ShopInformations = { ...shopData };
      newShopData[fieldName] = newValue;
      setShopData(newShopData);
    },
    [setShopData, shopData]
  );

  return (
    <div>
      <SectionTitle>Informations horaire</SectionTitle>

      <ScheduleBox>
        <HoraryPicker
          headerTitle="Heures d'ouverture"
          shopDataState={shopDataState}
          field="openHours"
        />
        <HoraryPicker
          headerTitle="Heures de retrait"
          shopDataState={shopDataState}
          field="pickupHours"
        />
      </ScheduleBox>

      <FieldsGrid>
        {pickupFields.map((field) => (
          <InputField
            key={field.name}
            label={field.label}
            value={shopData[field.name]}
            defaultValue={shopData.name}
            onChange={(event) => updateShopState(field.name, Number(event.target.value))}
            type="number"
            required
          />
        ))}
      </FieldsGrid>
    </div>
  );
};

export default ScheduleForm;
