import { UnpackNestedValue } from 'react-hook-form';

export const text = /^[A-Z ]+$/i;
export const password = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
export const email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const phone = /^((\+)33|0)[1-9](\d{2}){4}$/;
export const address = /^[A-zÀ-ú0-9 _]*[A-zÀ-ú0-9][A-zÀ-ú0-9 _]*$/;
export const url =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const textErrorMessage = "Ce champ n'accepte que du texte.";
export const passwordErrorMessage =
  'Le mot de passe doit contenir entre 8 et 15 caractères avec au moins une lettre minuscule, une lettre majuscule, un nombre et un caractère spécial.';
export const emailErrorMessage = 'Veuillez entrer une adresse mail valide. i.e: xxxx@xxx.xx';
export const phoneErrorMessage = 'Veuillez entrer un numero de téléphone valide. i.e: +33xxxxxxxxx';
export const addressErrorMessage =
  'Veuillez entrer une adresse valide. i.e: Place Bellecour 69002 Lyon';
export const urlErrorMessage = 'Veuillez entrer une Url valide. i.e: https://www.xxxxxx.xxx';

export interface SignupFormInterface<T> {
  handleNext: (values: UnpackNestedValue<T>) => void;
  handlePrevious?: () => void;
  formFields: SignupFormField<T>[];
}

export interface SignupFormField<T> {
  label: string;
  name: keyof T;
  type: string;
  required: boolean;
  pattern: RegExp;
  errorMessage: string;
}
