import { FC, useEffect, useState } from 'react';
import Thumbnail from '../../../components/Thumbnail/Thumbnail';
import Dropzone from '../../../components/Dropzone/Dropzone';
import { TitleText, ThumbnailsMosaic, ErrorBox, CustomSwitch, Header } from './UploadPage.style';
import { useMutation } from '@apollo/client';
import { UPLOAD_FILE, GET_FILES } from '../../../services/gqlHolder';
import { FileInfo, FileList } from '../../../graphQl/api_generated';
import { useQuery } from '@apollo/client';
import deleteIcon from '../../../assets/bin.svg';

const UploadPage: FC = () => {
  const [imageToUpload, setImageToUpload] = useState<File[]>([]);
  const [uploadError, setUploadError] = useState<Error | null>(null);
  const [isDeleteMode, setIsDeleteMode] = useState(false);

  const [listFiles, setListFiles] = useState<FileInfo[]>([]);

  const {
    data: dataFiles,
    loading: loadingGetFiles,
    error: fileFetchError,
    refetch,
  } = useQuery(GET_FILES);
  const [mutate, { error: uploadFileError }] = useMutation(UPLOAD_FILE, {
    // Update cached query with new file that just been updated
    update(cache, { data: { uploadFile } }) {
      const queryResult: { getMyFileList: FileList } | null = cache.readQuery({
        query: GET_FILES,
      });

      if (queryResult?.getMyFileList?.files) {
        const queryFiles = [...queryResult.getMyFileList.files];

        cache.writeQuery({
          query: GET_FILES,
          data: {
            getMyFileList: {
              files: queryFiles.concat([
                {
                  name: `${uploadFile}`,
                  url: `${uploadFile}`,
                },
              ]),
            },
          },
        });

        const updatedQuery: { getMyFileList: FileList } | null = cache.readQuery({
          query: GET_FILES,
        });

        if (updatedQuery && updatedQuery.getMyFileList.files.length > queryFiles.length) {
          setListFiles(updatedQuery.getMyFileList.files);
        }
      }
    },
  });

  // Set DB files on state
  useEffect(() => {
    if (!dataFiles) return;

    setListFiles(dataFiles.getMyFileList.files);
  }, [loadingGetFiles, dataFiles]);

  // Upload New Files
  useEffect(() => {
    if (imageToUpload) {
      if (imageToUpload.length === 0) return;

      imageToUpload.forEach((file) => {
        mutate({ variables: { file } });
      });
    }
  }, [imageToUpload, mutate]);

  if (fileFetchError) {
    console.error(`Error fetching file : ${fileFetchError}`);
  }

  if (uploadFileError) {
    console.error(`Error uploading file : ${uploadFileError}`);
  }

  return (
    <div>
      <Header>
        <TitleText>Mise en ligne d'image</TitleText>
        <div>
          <CustomSwitch onChange={(event) => setIsDeleteMode(event.target.checked)} />
          <img src={deleteIcon} alt="delete-icon" />
        </div>
      </Header>

      <Dropzone setImageToUpload={setImageToUpload} setUploadError={setUploadError} />

      {uploadError && (
        <ErrorBox>
          <h4>Un erreur est survenue lors de l'envoi d'images:</h4>
          <p> {uploadError} </p>
        </ErrorBox>
      )}

      <ThumbnailsMosaic>
        {listFiles.map((image, index) => (
          <Thumbnail key={index} image={image} isDeleteMode={isDeleteMode} updateFiles={refetch} />
        ))}
      </ThumbnailsMosaic>
    </div>
  );
};

export default UploadPage;
