import { atom, selector } from 'recoil';

const productsIdToDeleteState = atom<string[]>({
  key: 'productsIdToDeleteState',
  default: [],
});

export const getProductsIdToDelete = selector({
  key: 'getProductsIdToDelete',
  get: ({ get }) => get(productsIdToDeleteState),
});

export const addProductsIdToDelete = selector({
  key: 'addProductsIdToDelete',
  get: (_) => null,
  set: ({ set, get }, productId) => {
    const productsIdToDelete = get(productsIdToDeleteState);
    set(productsIdToDeleteState, [...productsIdToDelete, productId as string]);
  },
});

export const removeProductsIdToDelete = selector({
  key: 'removeProductsIdToDelete',
  get: (_) => null,
  set: ({ set, get }, productId) => {
    const productsIdToDelete = get(productsIdToDeleteState);
    set(
      productsIdToDeleteState,
      productsIdToDelete.filter((p) => p !== productId)
    );
  },
});

export const toggleProductIdToRemove = selector({
  key: 'toggleProductIdToRemove',
  get: (_) => null,
  set: ({ set, get }, productId) => {
    const productsIdToDelete = get(productsIdToDeleteState);
    if (productsIdToDelete.includes(productId as string)) {
      set(removeProductsIdToDelete, productId);
    } else {
      set(addProductsIdToDelete, productId);
    }
  },
});
