import styled from 'styled-components';
import { Dialog, withStyles } from '@material-ui/core';
import { color } from '../../../theme';

export const OpenHourBox = styled.div`
  width: 48%;
`;

export const OpenHourTitle = styled.div``;

export const WeekDaysGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  grid-column-gap: 5px;
  margin: 10px 0 20px 0;
`;

export const Row = styled.div`
  background-color: ${color.primaryLighter};
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-column-gap: 5px;
`;

export const WeekDayColumn = styled.div`
  text-transform: capitalize;
  margin: 2px 0;
`;

interface HoursListTextProps {
  isInvalid: boolean;
}

export const HoursListText = styled.div<HoursListTextProps>`
  text-align: right;
  color: ${(props) => (props.isInvalid ? color.angry : color.primaryFont)}
  &:hover {
    font-weight: bold;
  }
`;

/* MODAL */
export const DialogContainer = withStyles({
  root: {
    '& .MuiDialog-paper': {
      display: 'flex',
      alignItems: 'center',
      padding: '20px',
    },
  },
})(Dialog);

export const Header = styled.div`
  text-align: center;
  text-transform: capitalize;
`;

export const AppTimeRangeButtonBox = styled.div`
  margin-top: 20px;
`;
