import { Credentials, ShopInformations } from '../../graphQl/api_generated';
import {
  address,
  addressErrorMessage,
  email,
  emailErrorMessage,
  password,
  passwordErrorMessage,
  phone,
  phoneErrorMessage,
  SignupFormField,
  text,
  textErrorMessage,
  url,
  urlErrorMessage,
} from './formUtils';

export const userFormFields: SignupFormField<Credentials>[] = [
  {
    label: 'Email',
    name: 'email',
    type: 'email',
    required: true,
    pattern: email,
    errorMessage: emailErrorMessage,
  },
  {
    label: 'Mot de passe',
    name: 'password',
    type: 'password',
    required: true,
    pattern: password,
    errorMessage: passwordErrorMessage,
  },
];

export const shopFormFields: SignupFormField<ShopInformations>[] = [
  {
    label: 'Nom de la boutique',
    name: 'name',
    type: 'text',
    required: true,
    pattern: text,
    errorMessage: textErrorMessage,
  },
  {
    label: 'Téléphone',
    name: 'phone',
    type: 'tel',
    required: true,
    pattern: phone,
    errorMessage: phoneErrorMessage,
  },
  {
    label: 'Email',
    name: 'email',
    type: 'email',
    required: true,
    pattern: email,
    errorMessage: emailErrorMessage,
  },
  {
    label: 'Adresse',
    name: 'address',
    type: '',
    required: true,
    pattern: address,
    errorMessage: addressErrorMessage,
  },
];

export const networkFormFields: SignupFormField<ShopInformations>[] = [
  {
    label: 'Url Facebook',
    name: 'facebookLink',
    type: 'url',
    required: false,
    pattern: url,
    errorMessage: urlErrorMessage,
  },
  {
    label: 'Url Instagram',
    name: 'instagramLink',
    type: 'url',
    required: false,
    pattern: url,
    errorMessage: urlErrorMessage,
  },
  {
    label: 'Url Twitter',
    name: 'twitterLink',
    type: 'url',
    required: false,
    pattern: url,
    errorMessage: urlErrorMessage,
  },
];
