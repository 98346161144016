import { atom, selector } from 'recoil';
import { dayName } from '../utils/enum';

export const invalidDaysInTenantOpenHoursStore = atom({
  key: 'invalidDaysInTenantOpenHoursStore',
  default: [] as dayName[],
});

export const addInvalidOpenHoursSelector = selector({
  key: 'addInvalidOpenHoursSelector',
  get: (_) => null,
  set: ({ set, get }, dayToAdd) => {
    const invalidDaysInTenantOpenHours = get(invalidDaysInTenantOpenHoursStore);
    set(invalidDaysInTenantOpenHoursStore, [...invalidDaysInTenantOpenHours, dayToAdd as dayName]);
  },
});

export const removeInvalidOpenHoursSelector = selector({
  key: 'removeInvalidOpenHoursSelector',
  get: (_) => null,
  set: ({ set, get }, dayToRemove) => {
    const invalidDaysInTenantOpenHours = get(invalidDaysInTenantOpenHoursStore);

    set(invalidDaysInTenantOpenHoursStore, [
      ...invalidDaysInTenantOpenHours.filter(
        (_, index) => invalidDaysInTenantOpenHours.indexOf(dayToRemove as dayName) !== index
      ),
    ]);
  },
});

export const invalidDaysInTenantPickupHoursStore = atom({
  key: 'invalidDaysInTenantPickupHoursStore',
  default: [] as dayName[],
});

export const addInvalidPickupHoursSelector = selector({
  key: 'addInvalidPickupHoursSelector',
  get: (_) => null,
  set: ({ set, get }, dayToAdd) => {
    const invalidDaysInTenantPickupHours = get(invalidDaysInTenantPickupHoursStore);
    set(invalidDaysInTenantPickupHoursStore, [
      ...invalidDaysInTenantPickupHours,
      dayToAdd as dayName,
    ]);
  },
});

export const removeInvalidPickupHoursSelector = selector({
  key: 'removeInvalidPickupHoursSelector',
  get: (_) => null,
  set: ({ set, get }, dayToRemove) => {
    const invalidDaysInTenantPickupHours = get(invalidDaysInTenantPickupHoursStore);

    set(invalidDaysInTenantPickupHoursStore, [
      ...invalidDaysInTenantPickupHours.filter(
        (_, index) => invalidDaysInTenantPickupHours.indexOf(dayToRemove as dayName) !== index
      ),
    ]);
  },
});
