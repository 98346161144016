import { useCallback, useState, useEffect, useRef } from 'react';
import { TextField, Select, MenuItem, TableCell } from '@material-ui/core';
import { Product, WholesaleMeasureUnitProduct } from '../../graphQl/api_generated';
import { getShortUnit } from '../../utils/units';
import {
  CustomCheckbox,
  CustomSwitch,
  CustomTableRow,
  DisplayTableCell,
  SellTableCell,
  UploadCell,
} from './ProductsTable.style';
import {
  getDisplayedMeasureUnit,
  getDisplayedPrice,
  getWholesalePrice,
  isDisplayedQuantityEditable,
  isWholesaleMeasureUnitEditable,
} from '../../utils/computeProduct';
import { DynamicText, SmallField } from '../CreateProduct/CreateProduct.style';
import { useMutation } from '@apollo/client';
import { UPDATE_PRODUCT } from '../../services/gqlHolder';
import { useSetRecoilState } from 'recoil';
import { displayAlertSelector } from '../../store/alert.store';
import { useDebounce } from '../../utils/functions';
import { toggleProductIdToRemove } from '../../modules/backoffice/store/delete-products.store';

interface ProductRowProps {
  product: Product;
}

export default function ProductRow(props: ProductRowProps) {
  const [product, setProduct] = useState<Product>({ ...props.product });
  const firstRender = useRef(true);
  const productStateBeforeMutation = useRef(product);
  const toggleProductSelected = useSetRecoilState(toggleProductIdToRemove);
  const displayAlert = useSetRecoilState(displayAlertSelector);

  const [updateProductMutation] = useMutation(UPDATE_PRODUCT, {
    onCompleted: () =>
      displayAlert({ severity: 'success', message: `${product.name} mis à jour avec succes.` }),
    onError: () =>
      displayAlert({
        severity: 'error',
        message: `Impossible de mettre à jour ${product.name}.`,
      }),
  });

  const debouncedUpdateProduct = useDebounce(product, 1500);

  useEffect(() => {
    if (!firstRender.current) {
      const {
        __typename,
        wholesalePrice,
        displayedPrice,
        needsJar,
        displayedMeasureUnit,
        ...updateProduct
      } = product;
      updateProduct.sellingPrice = parseFloat(updateProduct.sellingPrice).toFixed(2);

      updateProductMutation({
        variables: {
          product: {
            ...updateProduct,
          },
        },
      }).then((data) => {
        if (data === undefined) {
          setProduct(productStateBeforeMutation.current);
        } else {
          setProduct(product);
          productStateBeforeMutation.current = product;
        }
      });
    }
    firstRender.current = false;
  }, [debouncedUpdateProduct]);

  const wholesalePrice = useCallback(
    () =>
      getWholesalePrice({
        wholesaleMeasureUnit: product.wholesaleMeasureUnit,
        sellingMeasureUnit: product.sellingMeasureUnit,
        sellingPrice: product.sellingPrice,
        capacity: product.capacity,
      }),
    [product]
  );

  return (
    <CustomTableRow>
      <TableCell>
        <CustomCheckbox onChange={() => toggleProductSelected(product.id)} />
      </TableCell>

      <TableCell>
        <TextField
          value={product.category}
          multiline
          onChange={(event) => setProduct((prev) => ({ ...prev, category: event.target.value }))}
        />
      </TableCell>

      <TableCell>
        <TextField
          value={product.subcategory}
          multiline
          onChange={(event) => setProduct((prev) => ({ ...prev, subcategory: event.target.value }))}
        />
      </TableCell>

      <TableCell>
        <TextField
          value={product.name}
          multiline
          onChange={(event) => setProduct((prev) => ({ ...prev, name: event.target.value }))}
        />
      </TableCell>

      <TableCell>
        <CustomSwitch
          color="default"
          checked={product.isAvailable}
          onChange={(event) =>
            setProduct((prev) => ({ ...prev, isAvailable: event.target.checked }))
          }
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </TableCell>

      <SellTableCell>
        <TextField
          value={product.sellingPrice}
          onChange={(event) =>
            setProduct((prev) => ({
              ...prev,
              sellingPrice: event.target.value,
            }))
          }
          type="number"
          multiline
        />
      </SellTableCell>

      <SellTableCell>
        <Select
          value={product.sellingMeasureUnit}
          onChange={(event) =>
            setProduct((prev) => ({
              ...prev,
              wholesaleMeasureUnit: event.target.value as WholesaleMeasureUnitProduct,
              sellingMeasureUnit: event.target.value as WholesaleMeasureUnitProduct,
            }))
          }>
          <MenuItem value="LITRE">L</MenuItem>
          <MenuItem value="KILOGRAMME">kg</MenuItem>
          <MenuItem value="UNIT">u</MenuItem>
        </Select>
      </SellTableCell>

      <TableCell>
        <CustomSwitch
          color="default"
          checked={product.isDraft}
          onChange={(event) => setProduct((prev) => ({ ...prev, isDraft: event.target.checked }))}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </TableCell>

      <TableCell>
        <TextField
          value={product.loyaltyPoints}
          onChange={(event) =>
            setProduct((prev) => ({
              ...prev,
              loyaltyPoints: Number(event.target.value),
            }))
          }
          type="number"
          multiline
        />
      </TableCell>

      <TableCell>
        <CustomSwitch
          checked={product.needsJar}
          disabled={product.sellingMeasureUnit !== WholesaleMeasureUnitProduct.Litre}
          onChange={(event) => setProduct((prev) => ({ ...prev, needsJar: event.target.checked }))}
          color={'default'}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </TableCell>

      <TableCell>
        <DynamicText>
          {(wholesalePrice() && parseFloat(`${wholesalePrice()}`).toFixed(2)) || '-'}
        </DynamicText>
      </TableCell>

      <TableCell>
        {isWholesaleMeasureUnitEditable(product.sellingMeasureUnit) ? (
          <Select
            value={product.wholesaleMeasureUnit}
            onChange={(event) =>
              setProduct((prev) => ({
                ...prev,
                wholesaleMeasureUnit: event.target.value as WholesaleMeasureUnitProduct,
              }))
            }>
            <MenuItem value="LITRE">L</MenuItem>
            <MenuItem value="KILOGRAMME">kg</MenuItem>
            <MenuItem value="UNIT">u</MenuItem>
          </Select>
        ) : (
          <DynamicText>{getShortUnit(product.sellingMeasureUnit)}</DynamicText>
        )}
      </TableCell>

      <DisplayTableCell>
        <DynamicText>
          {parseFloat(
            `${getDisplayedPrice({
              sellingMeasureUnit: product.sellingMeasureUnit,
              sellingPrice: product.sellingPrice,
              displayedQuantity: product.displayedQuantity,
            })}`
          ).toFixed(2)}
        </DynamicText>
      </DisplayTableCell>

      <DisplayTableCell>
        <DynamicText>
          <SmallField
            disabled={!isDisplayedQuantityEditable(product.sellingMeasureUnit)}
            type="number"
            value={product.displayedQuantity}
            id={'displayQuantity'}
            onChange={(event) =>
              setProduct((prev) => ({ ...prev, displayedQuantity: +event.target.value }))
            }
          />
          {getShortUnit(getDisplayedMeasureUnit(product.sellingMeasureUnit))}
        </DynamicText>
      </DisplayTableCell>

      <TableCell>
        <TextField
          value={product.quantityIncrement}
          type="number"
          onChange={(event) =>
            setProduct((prev) => ({ ...prev, quantityIncrement: +event.target.value }))
          }
        />
        {getShortUnit(getDisplayedMeasureUnit(product.sellingMeasureUnit))}
      </TableCell>

      <UploadCell>
        <TextField
          value={product.pictureUrl || '-'}
          onChange={(event) => setProduct((prev) => ({ ...prev, pictureUrl: event.target.value }))}
          type="url"
        />
      </UploadCell>
    </CustomTableRow>
  );
}
