import { FC, useCallback } from 'react';
import { FieldsGrid, AddressBox } from './ShopInfosForm.style';
import { TenantSectionProps, InputField, SectionTitle } from '../common';
import { ShopInformations } from '../../../graphQl/api_generated';

interface FieldData {
  name: fieldsName;
  label: string;
  isRequired: boolean;
  // add a type key if there are sereval fields types in the same form section
}

type FieldList = FieldData[];

type fieldsName =
  | 'name'
  | 'phone'
  | 'email'
  | 'address'
  | 'instagramLink'
  | 'facebookLink'
  | 'twitterLink';

const mainDataFields: FieldList = [
  { name: 'name', label: 'Nom de la boutique', isRequired: true },
  { name: 'phone', label: 'Téléphone', isRequired: true },
  { name: 'email', label: 'Email', isRequired: true },
];

const socialMediaFields: FieldList = [
  { name: 'instagramLink', label: 'Instagram', isRequired: false },
  { name: 'facebookLink', label: 'Facebook', isRequired: false },
  { name: 'twitterLink', label: 'Twitter', isRequired: false },
];

const ShopInfosForm: FC<TenantSectionProps> = (props) => {
  const {
    shopDataState: [shopData, setShopData],
  }: TenantSectionProps = props;

  const updateShopState = useCallback(
    (fieldName: fieldsName, newValue: string) => {
      const newShopData: ShopInformations = { ...shopData };
      newShopData[fieldName] = newValue;
      setShopData(newShopData);
    },
    [setShopData, shopData]
  );

  return (
    <div>
      <SectionTitle>
        Informations de la boutique {!!shopData.slug && `: /${shopData.slug}`}
      </SectionTitle>
      <FieldsGrid>
        {mainDataFields.map((field) => (
          <InputField
            key={field.name}
            label={field.label}
            value={shopData[field.name]}
            defaultValue={shopData.name}
            onChange={(event) => updateShopState(field.name, event.target.value)}
            required
          />
        ))}

        <AddressBox>
          <InputField
            label="Adresse complète"
            value={shopData.address}
            onChange={(event) => updateShopState('address', event.target.value)}
          />
        </AddressBox>
      </FieldsGrid>

      <SectionTitle>Liens Réseaux sociaux</SectionTitle>
      <FieldsGrid>
        {socialMediaFields.map((field) => (
          <InputField
            key={field.name}
            label={field.label}
            value={shopData[field.name]}
            defaultValue={shopData.name}
            onChange={(event) => updateShopState(field.name, event.target.value)}
            required
          />
        ))}
      </FieldsGrid>
    </div>
  );
};

export default ShopInfosForm;
