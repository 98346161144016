import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  grid-column-gap: 20px;
`;

export const SwitchRow = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-right: 30px;
  }
`;

export const LoyaltyFieldsBox = styled.div<{ isMuted: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  gap: 20px;

  opacity: ${(props) => (props.isMuted ? 0.5 : 1)};
  pointer-events: ${(props) => (props.isMuted ? 'none' : 'unset')};
`;
