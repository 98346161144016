import { FC, useCallback, useMemo, useEffect } from 'react';
import { MiddleSpace, ComponentBox, RemoveButton, RemoveButtonBox, Field } from './TimeRange.style';
import { ShopInformations } from '../../../graphQl/api_generated';
import { formatUTCTime } from '../../../utils/dateChecks';
import moment from 'moment';
import { useSetRecoilState } from 'recoil';
import { displayAlertSelector } from '../../../store/alert.store';
import {
  addInvalidOpenHoursSelector,
  removeInvalidOpenHoursSelector,
  addInvalidPickupHoursSelector,
  removeInvalidPickupHoursSelector,
} from '../../../store/tenant-errors.store';
import { dayName } from '../../../utils/enum';

interface TimeRangeProps {
  index: number;
  shopDataState: [ShopInformations, (newValue: ShopInformations) => void];
  dayToUpdate: dayName;
  field: 'openHours' | 'pickupHours';
}

const TimeRange: FC<TimeRangeProps> = (props) => {
  const { index, shopDataState, dayToUpdate, field }: TimeRangeProps = props;

  const displayAlert = useSetRecoilState(displayAlertSelector);
  const addtInvalidOpenHours = useSetRecoilState(addInvalidOpenHoursSelector);
  const removeInvalidOpenHours = useSetRecoilState(removeInvalidOpenHoursSelector);
  const addtInvalidPickupHours = useSetRecoilState(addInvalidPickupHoursSelector);
  const removeInvalidPickupHours = useSetRecoilState(removeInvalidPickupHoursSelector);

  const [shopData, setShopData] = shopDataState;

  const removeTimeRange = useCallback(() => {
    let updatedShop: ShopInformations = { ...shopData };
    updatedShop[field].week[dayToUpdate].splice(index, 1);
    setShopData(updatedShop);
  }, [shopData, setShopData, index, dayToUpdate, field]);

  const dayToUpdateData = useMemo(
    () => shopData[field].week[dayToUpdate],
    [shopData, field, dayToUpdate]
  );

  const isDateOverlapping = (horaryToCheck: string) =>
    dayToUpdateData.some((horary) => moment(horaryToCheck).isBetween(horary.open, horary.close));

  const errorOnOpenField =
    dayToUpdateData.length > 1 && isDateOverlapping(dayToUpdateData[index].open);

  useEffect(() => {
    if (errorOnOpenField) {
      field === 'openHours'
        ? addtInvalidOpenHours(dayToUpdate)
        : addtInvalidPickupHours(dayToUpdate);
      displayAlert({ severity: 'warning', message: 'Les horaires se chevauchent !' });
    } else {
      field === 'openHours'
        ? removeInvalidOpenHours(dayToUpdate)
        : removeInvalidPickupHours(dayToUpdate);
    }
  }, [
    errorOnOpenField,
    displayAlert,
    addtInvalidOpenHours,
    removeInvalidOpenHours,
    removeInvalidPickupHours,
    addtInvalidPickupHours,
    dayToUpdate,
    field,
  ]);

  return (
    <>
      <ComponentBox>
        <Field
          label="Heure d'ouverture"
          type="time"
          onChange={(event: any) => (dayToUpdateData[index].open = event.target.valueAsDate)}
          defaultValue={formatUTCTime(dayToUpdateData[index].open)}
          inputProps={{ min: 0, max: 23 }}
          error={errorOnOpenField}
        />

        <MiddleSpace />

        <Field
          label="Heure de fermeture"
          type="time"
          onChange={(event: any) => (dayToUpdateData[index].close = event.target.valueAsDate)}
          defaultValue={formatUTCTime(dayToUpdateData[index].close)}
          inputProps={{ min: 0, max: 23 }}
        />
        <RemoveButtonBox onClick={() => removeTimeRange()}>
          <RemoveButton />
        </RemoveButtonBox>
      </ComponentBox>
    </>
  );
};

export default TimeRange;
