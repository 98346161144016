import { FC, useCallback } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { SwitchRow, Row, LoyaltyFieldsBox } from './SellingInfosForm.style';
import { TenantSectionProps, InputField, SectionTitle } from '../common';
import { ShopInformations } from '../../../graphQl/api_generated';
import { CustomSwitch } from '../../ProductsTable/ProductsTable.style';

const currencies = [
  { id: 'USD', label: 'Le dollar américain (USD)' },
  { id: 'EUR', label: "L'euro (EUR)" },
  { id: 'JPY', label: 'Le yen japonais (JPY)' },
  { id: 'GBP', label: 'La livre sterling (GBP)' },
  { id: 'AUD', label: 'Le dollar australien (AUD)' },
  { id: 'CAD', label: 'Le dollar canadien (CAD)' },
  { id: 'CHF', label: 'Le franc suisse (CHF)' },
  { id: 'CNH', label: 'Le renminbi chinois (CNH)' },
  { id: 'SEK', label: 'La couronne suédoise (SEK)' },
  { id: 'NZD', label: 'Le dollar néo-zélandais (NZD)' },
];

const SellingInfosForm: FC<TenantSectionProps> = (props) => {
  const { shopDataState, loyaltyProgramState }: TenantSectionProps = props;

  if (!loyaltyProgramState) throw Error('loyaltyProgramState is missing in SellingInfosForm props');

  const [shopData, setShopData] = shopDataState;
  const [loyaltyProgram, setLoyaltyProgram] = loyaltyProgramState;

  const updateShopStateBagPrice = useCallback(
    (newValue: number) => {
      const newShopData: ShopInformations = { ...shopData };
      newShopData.kraftBagsPrice = newValue;
      setShopData(newShopData);
    },
    [setShopData, shopData]
  );

  const updateShopStateCurrency = useCallback(
    (newValue: string) => {
      const newShopData: ShopInformations = { ...shopData };
      newShopData.defaultCurrency = newValue;
      setShopData(newShopData);
    },
    [setShopData, shopData]
  );

  return (
    <>
      <SectionTitle>Informations de vente</SectionTitle>
      <Row>
        <InputField
          label="Prix des sacs Kraft"
          type="number"
          value={shopData.kraftBagsPrice}
          defaultValue={shopData.kraftBagsPrice}
          onChange={(event) => updateShopStateBagPrice(Number(event.target.value))}
          inputProps={{ step: 0.01 }}
          required
        />
        <FormControl style={{ width: 'auto', minWidth: 100 }}>
          <InputLabel id="devise-select-label">Devise</InputLabel>
          <Select
            labelId="devise-select-label"
            onChange={(event) => updateShopStateCurrency(event.target.value as string)}
            value={shopData.defaultCurrency}>
            {currencies.map((currency) => (
              <MenuItem key={currency.id} value={currency.id}>
                {currency.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Row>

      <SectionTitle>Informations programme de fidélité</SectionTitle>
      <SwitchRow>
        <p>Programme de fidélité actif</p>
        Non
        <CustomSwitch
          color="default"
          checked={loyaltyProgram.isEnabled}
          onChange={(event) =>
            setLoyaltyProgram((prev) => ({ ...prev, isEnabled: event.target.checked }))
          }
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        Oui
      </SwitchRow>

      <LoyaltyFieldsBox isMuted={!loyaltyProgram.isEnabled}>
        <InputField
          label="Nom points de fidélité"
          type="text"
          value={loyaltyProgram.pointsName}
          defaultValue={loyaltyProgram.pointsName}
          onChange={(event) =>
            setLoyaltyProgram((prev) => ({ ...prev, pointsName: event.target.value }))
          }
        />

        <InputField
          label="Description du programme fidélité"
          type="text"
          value={loyaltyProgram.pointsDescription}
          defaultValue={loyaltyProgram.pointsDescription}
          onChange={(event) =>
            setLoyaltyProgram((prev) => ({ ...prev, pointsDescription: event.target.value }))
          }
          multiline
        />
      </LoyaltyFieldsBox>
    </>
  );
};

export default SellingInfosForm;
