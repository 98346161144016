import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ContainerBox,
  LogoBox,
  LoginBox,
  LoginInput,
  PasswordInput,
  SendCredButton,
  SignupButton,
  ErrorMessage,
} from './LoginPage.style';
import { LOGIN } from '../../../services/gqlHolder';
import { useMutation } from '@apollo/client';
import { useSetRecoilState } from 'recoil';
import { userInfo } from '../../../store/index';
import { User, Session } from '../../../graphQl/api_generated';
import { setToken, isValidSession } from '../../../utils/userChecks';
import { displayAlertSelector } from '../../../store/alert.store';
import { ReactComponent as IOSopLogo } from '../../../assets/ioshop-logo.svg';

function LoginPage() {
  const history = useHistory();
  const displayAlert = useSetRecoilState(displayAlertSelector);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const setUser = useSetRecoilState(userInfo);
  const [login, { error: LoginError }] = useMutation(LOGIN, {
    onError: () => displayAlert({ severity: 'error', message: 'Une erreur est survenue' }),
  });

  if (isValidSession()) {
    setTimeout(() => {
      history.push('/backoffice/orders');
    }, 0);
  }

  const sendCreds = () => {
    login({
      variables: {
        credentials: {
          email,
          password,
        },
      },
    })
      .then(async ({ data: { login } }) => {
        const { session, user }: { session: Session; user: User } = login;
        setToken(session);
        setUser(user);

        history.replace('/backoffice/orders');
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleKeyDown = (key: string) => {
    if (key === 'Enter') {
      sendCreds();
    }
  };

  return (
    <ContainerBox>
      <LogoBox>
        <IOSopLogo />
      </LogoBox>

      <LoginBox onKeyPress={(event) => handleKeyDown(event.key)}>
        <LoginInput
          type="text"
          id="login"
          name="login"
          placeholder="Nom de compte"
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <PasswordInput
          type="password"
          id="password"
          name="password"
          placeholder="Mot de passe"
          required
          onChange={(e) => setPassword(e.target.value)}
        />
      </LoginBox>
      {LoginError && <ErrorMessage>Nom d'utilisateur ou mot de passe invalide</ErrorMessage>}
      <SendCredButton onClick={sendCreds}>Se connecter</SendCredButton>
      <SignupButton onClick={() => history.push('/signup')}>Créer un compte</SignupButton>
    </ContainerBox>
  );
}

export default LoginPage;
