import { FC, useCallback } from 'react';
import { FieldsGrid } from '../../TenantForm/ShopInfosForm/ShopInfosForm.style';
import { EmailSectionProps, InputField, SectionTitle } from '../../TenantForm/common';
import { EmailInformations } from '../../../graphQl/api_generated';

interface FieldData {
  name: fieldsName;
  label: string;
  isRequired: boolean;
  // add a type key if there are several fields types in the same form section
}

type FieldList = FieldData[];

type fieldsName =
  | 'introductionOrderReceivedText'
  | 'introductionOrderReadyText'
  | 'productText'
  | 'containerText';

const dataFields: FieldList = [
  {
    name: 'introductionOrderReceivedText',
    label: 'Introduction de commande acceptée',
    isRequired: true,
  },
  { name: 'introductionOrderReadyText', label: 'Introduction de commande prête', isRequired: true },
  { name: 'productText', label: 'Texte produit', isRequired: false },
  { name: 'containerText', label: 'Text récipient', isRequired: false },
];

const EmailContentInfosForm: FC<EmailSectionProps> = (props) => {
  const {
    emailDataState: [emailData, setEmailData],
  }: EmailSectionProps = props;

  const updateEmailState = useCallback(
    (fieldName: fieldsName, newValue: string) => {
      const newEmailData: EmailInformations = { ...emailData };
      newEmailData[fieldName] = newValue;
      setEmailData(newEmailData);
    },
    [setEmailData, emailData]
  );

  return (
    <div>
      <SectionTitle>Informations de la boutique</SectionTitle>
      <FieldsGrid>
        {dataFields.map((field) => (
          <InputField
            key={field.name}
            label={field.label}
            value={emailData[field.name]}
            defaultValue={''}
            onChange={(event) => updateEmailState(field.name, event.target.value)}
            required={field.isRequired}
          />
        ))}
      </FieldsGrid>
    </div>
  );
};

export default EmailContentInfosForm;
