import styled from 'styled-components';
import { color } from '../../theme';
import { withStyles, TextField, Select, Button } from '@material-ui/core';
import { ReactComponent as CrossSvg } from '../../assets/exit-cross.svg';

export const ModalButton = withStyles({
  root: {
    backgroundColor: color.primaryLighter,
    textTransform: 'inherit',
    padding: 12,
    height: 40,
  },
})(Button);

export const ModalContainer = styled.div`
  background-color: white;
  width: 35vw;
  max-height: 85vh;
  overflow-y: scroll;
  scrollbar-width: none;
  min-width: 520px;
  padding: 40px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Title = styled.h1`
  font-weight: 300;
  font-size: 30px;
`;

export const SubTitle = styled.h3`
  font-weight: 700;
  font-size: 20px;
  margin: 32px 0px 15px 0px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 40px;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 55px;
`;

export const Label = styled.span`
  font-size: 15px;
  font-family: Roboto;
  color: ${(props: { muted?: boolean }) => (props.muted ? color.muted : color.primaryFont)};
`;

export const DynamicText = styled.p`
  font-size: 15px;
  font-family: Roboto;
  text-align: center;
`;

export const CenteredRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InputField = withStyles({
  root: {
    width: '100%',
  },
})(TextField);

export const SmallField = withStyles({
  root: {
    width: '65px',
  },
})(TextField);

export const SelectField = withStyles({
  root: {
    width: '27px',
    marginTop: '5px',
  },
})(Select);

export const SubmitButton = withStyles({
  root: {
    width: '308px',
    height: '60px',
    backgroundColor: color.primary,
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontSize: 18,
    textTransform: 'capitalize',
  },
})(Button);

export const ExitCross = styled(CrossSvg)`
  position: absolute;
  top: 40px;
  right: 40px;
  padding: 10px;
  cursor: pointer;
`;
