import { ChangeEvent, FC, useState } from 'react';
import ConfirmationModal from './ConfirmationModal';
import { ListItem, SelectBox } from './OrderTable.style';
import { Order } from '../../graphQl/api_generated';

interface OrderStatusDropdownProps {
  order: Order;
}

const OrderStatusDropdown: FC<OrderStatusDropdownProps> = (props) => {
  const { order } = props;

  const [status, setStatus] = useState(order.status);
  const [newStatus, setNewStatus] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const changeStatus = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setNewStatus(event.target.value as string);
    setOpen(true);
  };

  return (
    <>
      <SelectBox
        id="dropDownStatus"
        variant="outlined"
        value={status}
        disabled={status === 'Ready'}
        onChange={(e: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
          changeStatus(e)
        }
      >
        <ListItem key="inprogress" value={'InProgress'}>
          En Cours
        </ListItem>
        <ListItem key="ready" value={'Ready'}>
          Prête
        </ListItem>
      </SelectBox>
      <ConfirmationModal
        setStatus={setStatus}
        order={order}
        newStatus={newStatus}
        visibility={open}
        setVisibility={setOpen}
      />
    </>
  );
};

export default OrderStatusDropdown;
