import styled from 'styled-components';
import { color } from '../../../theme';
import { Switch, withStyles } from '@material-ui/core';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 40px 0 20px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const TitleText = styled.h2`
  color: ${color.neutralDarker};
  font-size: 40px;
  text-align: center;
`;

export const CustomSwitch = withStyles({
  root: {
    '& .Mui-checked': {
      color: color.deny,
    },
  },
  checked: {},
})(Switch);

export const ThumbnailsMosaic = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;

  justify-content: center;
  flex-wrap: wrap;
`;

export const ErrorBox = styled.div`
  color: ${color.angry};
  width: 50%;
  margin: 10px 25%;
`;
