import { FC, useCallback } from 'react';
import { FieldsGrid, AddressBox } from '../../TenantForm/ShopInfosForm/ShopInfosForm.style';
import { EmailSectionProps, InputField, SectionTitle } from '../../TenantForm/common';
import { EmailInformations } from '../../../graphQl/api_generated';

interface FieldData {
  name: fieldsName;
  label: string;
  isRequired: boolean;
  // add a type key if there are sereval fields types in the same form section
}

type FieldList = FieldData[];

type fieldsName = 'contactEmail' | 'orderReceptionEmail' | 'phoneNumber' | 'address';

const mainDataFields: FieldList = [
  { name: 'contactEmail', label: 'Email de contact', isRequired: true },
  { name: 'orderReceptionEmail', label: 'Email de réception de la commande', isRequired: true },
  { name: 'phoneNumber', label: 'Téléphone', isRequired: true },
];

const EmailShopInfosForm: FC<EmailSectionProps> = (props) => {
  const {
    emailDataState: [emailData, setEmailData],
  }: EmailSectionProps = props;

  const updateEmailState = useCallback(
    (fieldName: fieldsName, newValue: string) => {
      const newEmailData: EmailInformations = { ...emailData };
      newEmailData[fieldName] = newValue;
      setEmailData(newEmailData);
    },
    [setEmailData, emailData]
  );

  return (
    <div>
      <SectionTitle>Informations de la boutique</SectionTitle>
      <FieldsGrid>
        {mainDataFields.map((field) => (
          <InputField
            key={field.name}
            label={field.label}
            value={emailData[field.name]}
            defaultValue={''}
            onChange={(event) => updateEmailState(field.name, event.target.value)}
            required
          />
        ))}

        <AddressBox>
          <InputField
            label="Adresse complète"
            value={emailData.address}
            defaultValue={''}
            onChange={(event) => updateEmailState('address', event.target.value)}
          />
        </AddressBox>
      </FieldsGrid>
    </div>
  );
};

export default EmailShopInfosForm;
