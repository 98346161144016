import { DisplayedUnit, WholesaleMeasureUnitProduct } from '../graphQl/api_generated';

export const isJarNeeded = ({
  sellingMeasureUnit,
  wholesaleMeasureUnit,
}: {
  sellingMeasureUnit: WholesaleMeasureUnitProduct;
  wholesaleMeasureUnit: WholesaleMeasureUnitProduct;
}): boolean => {
  if (
    sellingMeasureUnit === WholesaleMeasureUnitProduct.Unit &&
    wholesaleMeasureUnit === WholesaleMeasureUnitProduct.Litre
  ) {
    return true;
  }

  if (sellingMeasureUnit === WholesaleMeasureUnitProduct.Litre) {
    return true;
  }

  return false;
};

export const isCapacityEditable = ({
  sellingMeasureUnit,
  wholesaleMeasureUnit,
}: {
  sellingMeasureUnit: WholesaleMeasureUnitProduct;
  wholesaleMeasureUnit: WholesaleMeasureUnitProduct;
}) => {
  if (
    sellingMeasureUnit === WholesaleMeasureUnitProduct.Unit &&
    (wholesaleMeasureUnit === WholesaleMeasureUnitProduct.Litre ||
      wholesaleMeasureUnit === WholesaleMeasureUnitProduct.Kilogramme)
  ) {
    return true;
  }
  if (sellingMeasureUnit !== WholesaleMeasureUnitProduct.Unit) {
    return false;
  }
  if (
    sellingMeasureUnit === WholesaleMeasureUnitProduct.Unit &&
    wholesaleMeasureUnit === WholesaleMeasureUnitProduct.Unit
  ) {
    return false;
  }
};

export const getCapacityMeasureUnit = (
  wholesaleMeasureUnit: WholesaleMeasureUnitProduct
): DisplayedUnit => {
  if (wholesaleMeasureUnit === WholesaleMeasureUnitProduct.Kilogramme) {
    return DisplayedUnit.Gramme;
  }
  if (wholesaleMeasureUnit === WholesaleMeasureUnitProduct.Litre) {
    return DisplayedUnit.Millilitre;
  } else return DisplayedUnit.Unit;
};

export const getDisplayedQuantityDefault = (sellingMeasureUnit: WholesaleMeasureUnitProduct) => {
  if (
    sellingMeasureUnit === WholesaleMeasureUnitProduct.Litre ||
    sellingMeasureUnit === WholesaleMeasureUnitProduct.Kilogramme
  ) {
    return 500;
  }
  if (sellingMeasureUnit === WholesaleMeasureUnitProduct.Unit) {
    return 1;
  } else {
    return 0;
  }
};

export const getDisplayedMeasureUnit = (
  sellingMeasureUnit: WholesaleMeasureUnitProduct
): DisplayedUnit => {
  if (sellingMeasureUnit === WholesaleMeasureUnitProduct.Litre) {
    return DisplayedUnit.Millilitre;
  } else if (sellingMeasureUnit === WholesaleMeasureUnitProduct.Kilogramme) {
    return DisplayedUnit.Gramme;
  } else {
    return DisplayedUnit.Unit;
  }
};

export const isDisplayedQuantityEditable = (sellingMeasureUnit: WholesaleMeasureUnitProduct) => {
  if (
    sellingMeasureUnit === WholesaleMeasureUnitProduct.Kilogramme ||
    sellingMeasureUnit === WholesaleMeasureUnitProduct.Litre
  ) {
    return true;
  }
  if (sellingMeasureUnit === WholesaleMeasureUnitProduct.Unit) {
    return false;
  }
};

export const getIncrement = (sellingMeasureUnit: WholesaleMeasureUnitProduct) => {
  if (
    sellingMeasureUnit === WholesaleMeasureUnitProduct.Litre ||
    sellingMeasureUnit === WholesaleMeasureUnitProduct.Kilogramme
  ) {
    return 100;
  }
  if (sellingMeasureUnit === WholesaleMeasureUnitProduct.Unit) {
    return 1;
  } else return 0;
};

const isBulk = (sellingMeasureUnit: WholesaleMeasureUnitProduct): boolean =>
  sellingMeasureUnit === WholesaleMeasureUnitProduct.Kilogramme ||
  sellingMeasureUnit === WholesaleMeasureUnitProduct.Litre;

export const getWholesalePrice = ({
  wholesaleMeasureUnit,
  sellingMeasureUnit,
  sellingPrice,
  capacity,
}: {
  wholesaleMeasureUnit: WholesaleMeasureUnitProduct;
  sellingMeasureUnit: WholesaleMeasureUnitProduct;
  sellingPrice: number;
  capacity: number;
}) => {
  if (isBulk(sellingMeasureUnit)) {
    return sellingPrice;
  }

  if (
    wholesaleMeasureUnit === WholesaleMeasureUnitProduct.Unit &&
    sellingMeasureUnit === WholesaleMeasureUnitProduct.Unit
  ) {
    return null;
  }

  if (capacity === 0) {
    return null;
  }

  if (
    (wholesaleMeasureUnit === WholesaleMeasureUnitProduct.Litre ||
      wholesaleMeasureUnit === WholesaleMeasureUnitProduct.Kilogramme) &&
    sellingMeasureUnit === WholesaleMeasureUnitProduct.Unit
  ) {
    return (1000 * sellingPrice) / capacity;
  }
};

export const getDisplayedPrice = ({
  sellingMeasureUnit,
  sellingPrice,
  displayedQuantity,
}: {
  sellingMeasureUnit: WholesaleMeasureUnitProduct;
  sellingPrice: number;
  displayedQuantity: number;
}) => {
  if (
    sellingMeasureUnit === WholesaleMeasureUnitProduct.Litre ||
    sellingMeasureUnit === WholesaleMeasureUnitProduct.Kilogramme
  ) {
    return (sellingPrice * displayedQuantity) / 1000;
  }
  if (sellingMeasureUnit === WholesaleMeasureUnitProduct.Unit) {
    return sellingPrice;
  }
};

export const isWholesaleMeasureUnitEditable = (sellingMeasureUnit: WholesaleMeasureUnitProduct) => {
  if (
    sellingMeasureUnit === WholesaleMeasureUnitProduct.Kilogramme ||
    sellingMeasureUnit === WholesaleMeasureUnitProduct.Litre
  ) {
    return false;
  }
  if (sellingMeasureUnit === WholesaleMeasureUnitProduct.Unit) {
    return true;
  }
};
