import { FC } from 'react';
import { Table, TableBody, TableContainer, TableHead, Paper } from '@material-ui/core';
import OrderRow from './OrderRow';
import { Order } from '../../graphQl/api_generated';
import { Cell, HeaderRow } from './OrderRow.style';

export const orderTableColumns = [
  { title: 'Nom et Prénom', percentWidth: 25 },
  { title: 'Date de retrait', percentWidth: 10 },
  { title: 'Heure de retrait', percentWidth: 10 },
  { title: 'Téléphone', percentWidth: 10 },
  { title: 'Email', percentWidth: 15 },
  { title: 'Date de la commande effectuée', percentWidth: 10 },
  { title: 'Statut de la commande', percentWidth: 10 },
  { title: 'Bocaux fournis', percentWidth: 10 },
];

interface OrderTableProps {
  orders: Order[];
}

const OrderTable: FC<OrderTableProps> = (props) => {
  const { orders = [] }: OrderTableProps = props;

  return (
    <TableContainer component={Paper}>
      <Table style={{ minWidth: 1500 }}>
        <TableHead>
          <HeaderRow>
            {orderTableColumns.map((cell) => (
              <Cell key={cell.title} percentWidth={cell.percentWidth}>
                {cell.title}
              </Cell>
            ))}
          </HeaderRow>
        </TableHead>

        <TableBody>
          {orders?.map((order, index) => (
            <OrderRow index={index} key={order.reference} order={order} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderTable;
