import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { color } from '../../theme';
import { EmailInformations, ShopInformations, LoyaltyProgram } from '../../graphQl/api_generated';
import { Dispatch, SetStateAction } from 'react';

export interface TenantSectionProps {
  shopDataState: [ShopInformations, Dispatch<SetStateAction<ShopInformations>>];
  loyaltyProgramState?: [LoyaltyProgram, Dispatch<SetStateAction<LoyaltyProgram>>];
}

export interface EmailSectionProps {
  emailDataState: [EmailInformations, (newValue: EmailInformations) => void];
}

export const SectionTitle = styled.div`
  color: ${color.primaryFont};
  font-family: 'lato';
  margin: 20px 0px 10px 0px;
  font-weight: bold;
`;

export const InputField = styled(TextField)`
  width: auto;
`;
