import { gql } from '@apollo/client';

export const SIGNUP = gql`
  mutation ($credentials: Credentials!) {
    signup(credentials: $credentials) {
      email
      createdAt
      updatedAt
      isDeleted
      roles
    }
  }
`;

export const LOGIN = gql`
  mutation ($credentials: Credentials!) {
    login(credentials: $credentials) {
      user {
        email
        createdAt
        updatedAt
        isDeleted
        roles
      }
      session {
        userId
        token
        refreshToken
        tokenExpiresAt
        refreshTokenExpiresAt
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ORDERS = gql`
  query ($nbPerPage: Int, $page: Int) {
    getMyOrders(nbPerPage: $nbPerPage, page: $page) {
      maxPages
      orders {
        reference
        email
        lastname
        firstname
        phone
        pickupDate
        status
        createdAt
        updatedAt
        hasBag
        amount
        products {
          orderReference
          productId
          price
          name
          quantity
          unit
          createdAt
          updatedAt
        }
        shopId
      }
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation ($order: OrderUpdate!) {
    updateOrder(orderUpdate: $order) {
      reference
      email
      lastname
      firstname
      phone
      pickupDate
      status
      createdAt
      updatedAt
      hasBag
      amount
      products {
        productId
        price
        name
        quantity
        unit
        createdAt
        updatedAt
      }
      shopId
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation ($file: Upload!) {
    uploadFile(file: $file)
  }
`;

export const GET_FILES = gql`
  query {
    getMyFileList {
      files {
        name
        url
      }
    }
  }
`;

export const CREATE_SHOP_INFORMATION = gql`
  mutation ($shop: ShopInformationsCreate!) {
    createMyShopInformations(shopInformations: $shop) {
      id
      name
      pickupHours {
        week {
          monday {
            open
            close
          }
          tuesday {
            open
            close
          }
          wednesday {
            open
            close
          }
          thursday {
            open
            close
          }
          friday {
            open
            close
          }
          saturday {
            open
            close
          }
          sunday {
            open
            close
          }
        }
      }
      phone
      pickupDelayMinutes
      pickupTimeIncrementMinutes
      openHours {
        week {
          monday {
            open
            close
          }
          tuesday {
            open
            close
          }
          wednesday {
            open
            close
          }
          thursday {
            open
            close
          }
          friday {
            open
            close
          }
          saturday {
            open
            close
          }
          sunday {
            open
            close
          }
        }
      }
      theme {
        primary
        contrast
        primaryFontColor
        primaryLighter
      }
      address
      kraftBagsPrice
      email
      defaultCurrency
      ownerId
      facebookLink
      twitterLink
      instagramLink
      headerDecorationUrl
      categoriesDecorationUrl
      backgroundDecorationUrl
      validationDecorationUrl
      logoUrl
      orderSummaryDecorationUrl
      cartDecorationUrl
      placeholderUrl
      primaryFont
      secondaryFont
      slug
    }
  }
`;

export const GET_MY_SHOP_INFORMATION = gql`
  query {
    getMyShopInformations {
      id
      name
      pickupHours {
        week {
          monday {
            open
            close
          }
          tuesday {
            open
            close
          }
          wednesday {
            open
            close
          }
          thursday {
            open
            close
          }
          friday {
            open
            close
          }
          saturday {
            open
            close
          }
          sunday {
            open
            close
          }
        }
      }
      phone
      pickupDelayMinutes
      pickupTimeIncrementMinutes
      openHours {
        week {
          monday {
            open
            close
          }
          tuesday {
            open
            close
          }
          wednesday {
            open
            close
          }
          thursday {
            open
            close
          }
          friday {
            open
            close
          }
          saturday {
            open
            close
          }
          sunday {
            open
            close
          }
        }
      }
      theme {
        primaryLighter
        primaryFontColor
        contrast
        primary
      }
      address
      kraftBagsPrice
      email
      defaultCurrency
      ownerId
      facebookLink
      twitterLink
      instagramLink
      headerDecorationUrl
      categoriesDecorationUrl
      facebookLink
      twitterLink
      instagramLink
      backgroundDecorationUrl
      validationDecorationUrl
      logoUrl
      orderSummaryDecorationUrl
      cartDecorationUrl
      placeholderUrl
      primaryFont
      secondaryFont
      slug
      loyaltyProgram {
        isEnabled
        pointsDescription
        pointsName
      }
    }
  }
`;

export const UPDATE_SHOP_INFORMATIONS = gql`
  mutation ($shop: ShopInformationsUpdate!) {
    updateMyShopInformations(shopInformations: $shop) {
      id
      name
      pickupHours {
        week {
          monday {
            open
            close
          }
          tuesday {
            open
            close
          }
          wednesday {
            open
            close
          }
          thursday {
            open
            close
          }
          friday {
            open
            close
          }
          saturday {
            open
            close
          }
          sunday {
            open
            close
          }
        }
      }
      phone
      pickupDelayMinutes
      pickupTimeIncrementMinutes
      openHours {
        week {
          monday {
            open
            close
          }
          tuesday {
            open
            close
          }
          wednesday {
            open
            close
          }
          thursday {
            open
            close
          }
          friday {
            open
            close
          }
          saturday {
            open
            close
          }
          sunday {
            open
            close
          }
        }
      }
      theme {
        primaryFontColor
        primary
        primaryLighter
        contrast
      }
      address
      kraftBagsPrice
      email
      defaultCurrency
      ownerId
      facebookLink
      twitterLink
      instagramLink
      headerDecorationUrl
      categoriesDecorationUrl
      backgroundDecorationUrl
      validationDecorationUrl
      logoUrl
      orderSummaryDecorationUrl
      cartDecorationUrl
      placeholderUrl
      primaryFont
      secondaryFont
      slug
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation ($products: [ProductCreate!]!) {
    createMyProducts(products: $products) {
      id
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation ($product: ProductUpdate!) {
    updateMyProduct(product: $product) {
      id
      shopId
      createdAt
      updatedAt
      deletedAt
      category
      subcategory
      name
      isAvailable
      sellingPrice
      sellingMeasureUnit
      capacity
      wholesaleMeasureUnit
      displayedQuantity
      quantityIncrement
      pictureUrl
      wholesalePrice
      displayedPrice
      needsJar
      displayedMeasureUnit
    }
  }
`;

export const GET_MY_EMAIL_INFORMATIONS = gql`
  query {
    getMyEmailInformations {
      shopId
      contactEmail
      orderReceptionEmail
      phoneNumber
      address
      bannerUrl
      logoUrl
      decorationUrl
      introductionOrderReceivedText
      introductionOrderReadyText
      productText
      containerText
    }
  }
`;

export const CREATE_EMAIL_INFORMATIONS = gql`
  mutation ($emailInfos: EmailInformationsCreate!) {
    createMyEmailInformations(emailInformations: $emailInfos) {
      shopId
      contactEmail
      orderReceptionEmail
      phoneNumber
      address
      bannerUrl
      logoUrl
      decorationUrl
      introductionOrderReceivedText
      introductionOrderReadyText
      productText
      containerText
    }
  }
`;

export const UPDATE_EMAIL_INFORMATIONS = gql`
  mutation ($emailInfos: EmailInformationsUpdate!) {
    updateMyEmailInformations(emailInformations: $emailInfos) {
      shopId
      contactEmail
      orderReceptionEmail
      phoneNumber
      address
      bannerUrl
      logoUrl
      decorationUrl
      introductionOrderReceivedText
      introductionOrderReadyText
      productText
      containerText
    }
  }
`;

export const GET_PRODUCTS = gql`
  query ($nbPerPage: Int, $page: Int) {
    getMyProducts(nbPerPage: $nbPerPage, page: $page) {
      maxPages
      products {
        id
        shopId
        createdAt
        updatedAt
        deletedAt
        category
        subcategory
        name
        isAvailable
        sellingPrice
        sellingMeasureUnit
        capacity
        wholesaleMeasureUnit
        displayedQuantity
        quantityIncrement
        pictureUrl
        wholesalePrice
        displayedPrice
        needsJar
        displayedMeasureUnit
        isDraft
        loyaltyPoints
      }
    }
  }
`;

export const DELETE_PRODUCTS = gql`
  mutation ($products: [UUID]!) {
    deleteMyProducts(products: $products)
  }
`;

export const DELETE_FILE = gql`
  mutation ($name: String!) {
    deleteFile(filename: $name)
  }
`;

export const CREATE_LOYALTY_PROGRAM = gql`
  mutation ($loyaltyProgram: LoyaltyProgramInput!) {
    createLoyaltyProgram(loyaltyProgram: $loyaltyProgram) {
      isEnabled
      pointsDescription
      pointsName
    }
  }
`;

export const UPDATE_LOYALTY_PROGRAM = gql`
  mutation ($loyaltyProgram: LoyaltyProgramInput!) {
    updateLoyaltyProgram(loyaltyProgram: $loyaltyProgram) {
      isEnabled
      pointsDescription
      pointsName
    }
  }
`;
