import styled from 'styled-components';
import { color } from '../../../theme';
import { withStyles, TextField } from '@material-ui/core';
import { ReactComponent as GlassSvg } from '../../../assets/magnifying-glass.svg';

export const PageBox = styled.div`
  margin: 40px 2.7vw;
`;

export const Title = styled.h1`
  font-size: 40px;
  margin-bottom: 24px;
  color: ${color.primaryFont};
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LeftHeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DeleteCircle = styled.div`
  background-color: ${color.primaryLighter};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${color.angry};
    opacity: 0.6;
  }
`;

export const Field = withStyles({
  root: {
    width: 250,
    marginLeft: 25,
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingRight: 40,
    },
  },
})(TextField);

export const Glass = styled(GlassSvg)`
  position: absolute;
  right: 2.7vw;
  margin: 10px;
`;
