import React from 'react';
import { useForm, Controller, Path } from 'react-hook-form';
import { Grid, TextField } from '@material-ui/core';

import { SignupFormInterface } from './formUtils';
import { StepperButton } from '../../modules/auth/LoginPage/LoginPage.style';

export default function SignupForm<T extends object>(props: SignupFormInterface<T>) {
  const { handleSubmit, control } = useForm<T>();

  return (
    <form onSubmit={handleSubmit(props.handleNext)}>
      <Grid container spacing={4}>
        {props.formFields.map(({ label, name, type, required, pattern, errorMessage }, index) => (
          <Grid item sm={6} key={`${label}-${index}`}>
            <Controller
              control={control}
              name={name as string as Path<T>}
              rules={{ required, pattern }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label={label}
                  type={type}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={!!error ? errorMessage : null}
                />
              )}
            />
          </Grid>
        ))}
        {props.handlePrevious && (
          <Grid item style={{ margin: '0 auto' }}>
            <StepperButton onClick={props.handlePrevious}>Retour</StepperButton>
          </Grid>
        )}

        <Grid item style={{ margin: '0 auto' }}>
          <StepperButton type="submit">Valider</StepperButton>
        </Grid>
      </Grid>
    </form>
  );
}
