import { FC, useState, ChangeEvent } from 'react';
import { useQuery } from '@apollo/client';
import OrderTable from '../../../components/OrderTable/OrderTable';
import { Footer } from './OrderPage.style';
import { GET_ORDERS } from '../../../services/gqlHolder';
import { Title, PageBox } from '../ProductPage/ProductPage.style';
import { Pagination } from '@material-ui/lab';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSetRecoilState } from 'recoil';
import { displayAlertSelector } from '../../../store/alert.store';

const QUANTITIES_PER_PAGE = [10, 30, 50, 100];

const OrderPage: FC = () => {
  const [page, setPage] = useState<number>(1);
  const [productPerPage, setProductPerPage] = useState<number>(QUANTITIES_PER_PAGE[0]);
  const displayAlert = useSetRecoilState(displayAlertSelector);

  const { data, refetch } = useQuery(GET_ORDERS, {
    variables: {
      page,
      nbPerPage: productPerPage,
    },
    onError: () =>
      displayAlert({
        severity: 'error',
        message: `Impossible de récupérer les commandes`,
      }),
  });

  function handlePagination(event: ChangeEvent<unknown>, page: number): void {
    setPage(page);
    refetch({
      page,
      nbPerPage: productPerPage,
    });
  }

  return (
    <PageBox>
      <Title>Gestion des commandes</Title>

      <OrderTable orders={data?.getMyOrders?.orders || []} />

      <Footer>
        <p>Par page :</p>

        <FormControl>
          <Select
            value={productPerPage}
            onChange={(event) => {
              setProductPerPage(parseInt(event.target.value as string));

              refetch({
                page: page,
                nbPerPage: parseInt(event.target.value as string),
              });
            }}
          >
            {QUANTITIES_PER_PAGE.map((q) => (
              <MenuItem key={q} value={q}>
                {q}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {data?.getMyOrders.maxPages > 1 && (
          <Pagination count={data.getMyOrders.maxPages} onChange={handlePagination} />
        )}
      </Footer>
    </PageBox>
  );
};

export default OrderPage;
