import styled from 'styled-components';

export const ThumbnailBox = styled.button`
  all: unset;
  position: relative;
  height: 140px;
  padding: 10px;
  cursor: pointer;

  &:hover div {
    opacity: 1;
  }

  &:hover .image {
    opacity: 0.3;
  }

  img {
    opacity: 1;
    display: block;
    height: 100%;
    width: auto;
    transition: 0.2s ease;
    backface-visibility: hidden;
  }
`;

export const Image = styled.img`
  opacity: 1;
  display: block;
  height: 100%;
  width: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
`;

export const IconBox = styled.div`
  transition: 0.2s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
`;
