import styled from 'styled-components';
import { color } from '../../../theme';
import { Link } from 'react-router-dom';

export const ComponentBox = styled.div`
  background-color: ${color.primaryLighter};
  height: 100%;
  position: fixed;
  margin-top: 100px;
  width: 187px;
  overflow: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

interface ItemBoxProps {
  isActive: boolean;
}

export const ItemBox = styled(Link)<ItemBoxProps>`
  cursor: pointer;
  padding: 16px 0;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: ${(props) => (props.isActive ? color.dropShadow : 'none')};
  background-color: ${(props) => (props.isActive ? color.primary : 'none')};
  font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
  text-decoration: none;
  color: ${color.neutralDarker};

  &:hover {
    font-weight: bold;
    background-color: ${color.primary};
  }
`;

export const Text = styled.span`
  width: 80%;
  margin-top: 10px;
`;
