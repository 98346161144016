import { FC, useCallback } from 'react';
import { ImagesUrlGrid } from '../../TenantForm/ThemeForm/ThemeForm.style';
import { EmailSectionProps, InputField, SectionTitle } from '../../TenantForm/common';
import { EmailInformations } from '../../../graphQl/api_generated';

interface FieldData {
  name: fieldsName;
  label: string;
  isRequired: boolean;
}
type FieldList = FieldData[];

type fieldsName = 'bannerUrl' | 'logoUrl' | 'decorationUrl';

const imagesFields: FieldList = [
  { name: 'bannerUrl', label: 'Url de la bannière', isRequired: true },
  { name: 'logoUrl', label: 'Url du logo', isRequired: true },
  { name: 'decorationUrl', label: `Url de l'image de décoration`, isRequired: false },
];

const EmailImagesForm: FC<EmailSectionProps> = (props) => {
  const {
    emailDataState: [emailData, setEmailData],
  }: EmailSectionProps = props;

  const updateEmailState = useCallback(
    (fieldName: fieldsName, newValue: string) => {
      const newEmailData: EmailInformations = { ...emailData };
      newEmailData[fieldName] = newValue;
      setEmailData(newEmailData);
    },
    [setEmailData, emailData]
  );

  return (
    <div>
      <SectionTitle>Url d'images décoratives</SectionTitle>
      <ImagesUrlGrid>
        {imagesFields.map((field) => (
          <InputField
            key={field.name}
            name={field.name}
            label={field.label}
            value={emailData[field.name]}
            defaultValue={''}
            onChange={(event) => updateEmailState(field.name, event.target.value)}
            required={field.isRequired}
          />
        ))}
      </ImagesUrlGrid>
    </div>
  );
};

export default EmailImagesForm;
