import styled from 'styled-components';
import { color } from '../../theme';
import { withStyles, Checkbox, TableCell, Switch, TableRow } from '@material-ui/core';

export const tableContainerStyle = { margin: '20px 0' };

export const CustomCheckbox = withStyles({
  root: {
    color: color.neutralDarker,
    '&$checked': {
      color: color.neutralDarker,
    },
  },
  checked: {},
})(Checkbox);

export const SellTableCell = withStyles({
  root: {
    backgroundColor: color.neutralDarkerGreyTransparent,
  },
})(TableCell);

export const DisplayTableCell = withStyles({
  root: {
    backgroundColor: color.tableMuted,
  },
})(TableCell);

export const CustomSwitch = withStyles({
  root: {
    '& .MuiSwitch-switchBase': {
      color: color.deny,
    },
    '& .Mui-checked': {
      color: color.accept,
    },
  },
  checked: {},
})(Switch);

export const UploadCell = withStyles({
  root: {
    color: color.primaryFont,
    fontSize: 12,
    fontStyle: 'italic',
    opacity: 0.5,
  },
})(TableCell);

export const CustomTableRow = styled(TableRow)`
  background-color: ${color.primaryLighter}
  &:nth-child(odd) {
    background-color: ${color.neutral}
  }
`;

export const NoProducts = styled.div`
  width: 200px;
  margin: 15px 100%;
  display: flex;
  font-style: italic;
`;
