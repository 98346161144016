import { ComponentBox, ItemBox, Text } from './Navbar.style';
import { useLocation } from 'react-router-dom';
import { ReactComponent as OrderManagement } from '../../../assets/order-management.svg';
import { ReactComponent as StockManagement } from '../../../assets/stock-management.svg';
import { ReactComponent as UploadImageIcon } from '../../../assets/upload-image-icon.svg';
import defaultShopImage from '../../../assets/Icon-ChangeTenantInfo.png';

const elements = [
  { name: 'orders', label: 'Gestion des commandes', icon: <OrderManagement /> },
  { name: 'products', label: 'Gestion de mon inventaire', icon: <StockManagement /> },
  { name: 'upload', label: 'Import d’image', icon: <UploadImageIcon /> },
  {
    name: 'tenant',
    label: 'Informations boutique',
    icon: <img src={defaultShopImage} alt="Icon-ChangeTenantInfo" />,
  },
];

const Navbar = (): JSX.Element => {
  const location = useLocation();

  return (
    <ComponentBox>
      {elements.map((element) => (
        <ItemBox
          key={element.name}
          isActive={location.pathname.includes(element.name)}
          to={`/backoffice/${element.name}`}
        >
          {element.icon}
          <Text>{element.label}</Text>
        </ItemBox>
      ))}
      <div style={{ height: 100 }} />
    </ComponentBox>
  );
};

export default Navbar;
