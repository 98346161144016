import { FC, useState } from 'react';
import { HeaderBox, DisconnectButtonImg, ShopImg, Title } from './Header.style';
import { useResetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { userInfo } from '../../store/index';
import { isValidSession } from '../../utils/userChecks';
import { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Helmet } from './Helmet';
import { useQuery } from '@apollo/client';
import { GET_MY_SHOP_INFORMATION } from '../../services/gqlHolder';
import { defaultShopInformations } from '../../modules/backoffice/TenantPage/common-data';
import { useCallback } from 'react';

const Header: FC = () => {
  const history = useHistory();
  const resetUser = useResetRecoilState(userInfo);
  const [open, setOpen] = useState(false);

  const { data } = useQuery(GET_MY_SHOP_INFORMATION); // We can set a Recoil state here if we need shopInfos somewhere else in the app

  const shopInfos = Object.assign(defaultShopInformations, data?.getMyShopInformations);

  const handleDisconnect = useCallback(() => {
    localStorage.removeItem('session');
    resetUser();

    history.replace('/');
  }, [history, resetUser]);

  useEffect(() => {
    if (isValidSession()) return;
    setOpen(true);
  }, []);

  const handleClose = useCallback(
    () => (event?: React.SyntheticEvent, reason?: string) => {
      setOpen(false);
      setTimeout(() => {
        history.replace('/');
      }, 0);
    },
    [history]
  );

  return (
    <div>
      <Helmet name={shopInfos.name} logoUrl={shopInfos.logoUrl} />

      <HeaderBox>
        <a href="/">
          <ShopImg src={shopInfos.logoUrl || ''} alt="shop-logo" />
        </a>
        <Title>Gestion de mon commerce</Title>

        <DisconnectButtonImg onClick={handleDisconnect} />
      </HeaderBox>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
          Please login first
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Header;
