import { Session } from '../graphQl/api_generated';
const rs = require('jsrsasign');

export const isValidSession = (): boolean => {
  const encodedSession = localStorage.getItem('session');

  if (!encodedSession) return false;

  const tokenInfo = getToken();

  if (!tokenInfo?.tokenExpiresAt) return false;

  const expireTime = new Date(tokenInfo?.tokenExpiresAt).getTime();

  return Date.now() < expireTime;
};

export const getToken = (): { token: Number[]; tokenExpiresAt: string } | undefined => {
  const encodedSession = localStorage.getItem('session');

  if (!encodedSession) return;

  try {
    const { token, tokenExpiresAt }: { token: Number[]; tokenExpiresAt: string } =
      rs.jws.JWS.parse(encodedSession).payloadObj;

    return { token, tokenExpiresAt };
  } catch (e) {
    console.error('Could not decode token');
  }

  return;
};

export const setToken = (session: Session) => {
  const { token, tokenExpiresAt } = session;
  const keyHeader = JSON.stringify({ alg: 'HS256' });
  const JSONinfo = JSON.stringify({
    token,
    tokenExpiresAt,
  });

  try {
    const encodedSession = rs.jws.JWS.sign('HS256', keyHeader, JSONinfo, '616161');
    localStorage.setItem('session', encodedSession);
  } catch (e) {
    console.error('Could not encode token to local storage');
  }
};
