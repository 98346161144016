import styled from 'styled-components';
import { ReactComponent as DisconnectButton } from '../../assets/disconnectButtonSvg.svg';
import { color } from '../../theme';

export const HeaderBox = styled.div`
  width: 100%;
  box-shadow: ${color.dropShadow};
  background-color: ${color.snow}
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
`;

export const ShopImg = styled.img`
  object-fit: contain;
  height: 90px;
  margin-left: 10px;
`;

export const Title = styled.div`
  text-align: left;
  font-weight: 300;
  font-size: 30px;
  font-family: Roboto;
  font-style: italic;
  margin: 0 60px;
`;

export const DisconnectButtonImg = styled(DisconnectButton)`
  margin-right: 2.7vw;
  cursor: pointer;
  position: absolute;
  right: 0;
`;
