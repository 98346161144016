import styled from 'styled-components';
import { Button as MUIButton, withStyles, Dialog } from '@material-ui/core';
import { color } from '../../theme';

export const StyledDialog = withStyles({
  root: {
    '& .MuiDialog-paperWidthSm': {
      width: 344,
      margin: 'auto',
      borderRadius: 10,
      padding: 40,
    },
  },
})(Dialog);

export const DialogTitleBox = styled.div`
  text-align: center;
  margin-bottom: 24px;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 400;
`;

export const DialogActionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Button = withStyles({
  root: (props: { cancelButton?: boolean }) => ({
    backgroundColor: props.cancelButton ? 'none' : color.accept,
    color: props.cancelButton ? color.neutralDarker : color.neutral,
    fontSize: 20,
    borderRadius: 5,
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    height: 60,
    width: 160,
    marginBottom: props.cancelButton ? 0 : 5,

    '&:hover': {
      backgroundColor: props.cancelButton ? color.deny : color.accept,
      color: props.cancelButton ? color.neutral : color.neutral,
    },
  }),
})(MUIButton);
