import { FC } from 'react';
import { ThumbnailBox, IconBox } from './Thumbnail.style';
import copyIcon from '../../assets/copy-icon.png';
import deleteIcon from '../../assets/bin.svg';
import { useSetRecoilState } from 'recoil';
import { displayAlertSelector } from '../../store/alert.store';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { DELETE_FILE } from '../../services/gqlHolder';

const assetsURL = process.env.REACT_APP_PUBLIC_ASSETS_ROOT_URL || window.env.PUBLIC_ASSETS_ROOT_URL;

interface ThumbnailProps {
  image: { url: string; name: string };
  isDeleteMode: boolean;
  updateFiles: () => Promise<ApolloQueryResult<any>>;
}

const Thumbnail: FC<ThumbnailProps> = (props) => {
  const [deleteImage] = useMutation(DELETE_FILE, {
    onCompleted: () => {
      displayAlert({ severity: 'success', message: `${image.name} supprimé avec succès` });
      props.updateFiles();
    },
    onError: () => displayAlert({ severity: 'error', message: 'Suppression impossible' }),
  });

  const { image, isDeleteMode }: ThumbnailProps = props;
  const displayAlert = useSetRecoilState(displayAlertSelector);

  const imageAbsoluteURL = `${assetsURL}/${image.url}`;

  const handleClick = () => {
    if (isDeleteMode) {
      // DELETE IMG
      deleteImage({ variables: { name: image.name } });
    } else {
      // COPY IMG URL
      navigator.clipboard.writeText(imageAbsoluteURL);
      displayAlert({
        severity: 'success',
        message: `${image.name} copié dans le press-papier`,
      });
    }
  };

  return (
    <ThumbnailBox onClick={handleClick}>
      <img src={imageAbsoluteURL} alt={image.name} className="image" />

      <IconBox>
        {isDeleteMode ? (
          <img src={deleteIcon} alt="delete-icon" />
        ) : (
          <img src={copyIcon} alt="copy-icon" className="copy-icon" />
        )}
      </IconBox>
    </ThumbnailBox>
  );
};

export default Thumbnail;
