import config from '../config.json';

const tenantColors = config.tenantColors;

const neutralGrey = 'rgba(0, 0, 0, 0.25)';

export const color = {
  primaryFont: tenantColors.primaryFont,
  primaryLighter: tenantColors.primaryLighter,
  contrast: tenantColors.contrast,
  primary: tenantColors.primary,

  neutralDarker: '#000000',
  neutralGrey,
  neutralDarkerGrey: '#c6bdae',
  neutralDarkerGreyTransparent: 'rgba(198, 189, 174, 0.2)',
  neutral: '#ffffff',
  snow: '#f9f9f9',

  angry: '#ED4337',
  muted: '#aeb6bf ',

  tableMuted: 'rgba(121, 158, 110, 0.2)',
  muiBorder: '#E0E0E0',
  muiDarkBorder: 'rgba(60, 60, 60, 1)',
  muiDarkBorderLighter: 'rgba(60, 60, 60, 0.5)',

  accept: '#799E6E',
  deny: '#DD344C',

  dropShadow: `0px 4px 4px ${neutralGrey}`,
};

export const availableFonts = ['Lato', 'Roboto', 'Playfair Display', 'Oswald'];
