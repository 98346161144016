import styled from 'styled-components';
import { color } from '../../../theme';

export const ContainerBox = styled.div`
  width: 385px;
  align-items: center;
  margin: auto;
  margin-top: 5%;
`;

export const LogoBox = styled.div`
  width: 300px;
  margin: auto;
`;

export const ErrorMessage = styled.p`
  font-family: Roboto;
  width: 100%;
  padding: 15px 0;
  color: red;
  text-align: center;
`;

export const LoginBox = styled.form`
  margin-top: 50px;
  background-color: ${color.neutral};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: solid ${color.neutralGrey} 2px;
  border-radius: 20px;
`;

export const InputFormNoBorder = styled.input`
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: solid ${color.neutralGrey} 2px;
  background-color: ${color.neutral};
  width: 308px;
  height: 35px;
  outline: none;
  color: ${color.primaryFont};
`;
export const LoginInput = styled(InputFormNoBorder)`
  font-size: 15px;
  font-family: Roboto;
  margin-top: 50px;
`;

export const PasswordInput = styled(InputFormNoBorder)`
  font-size: 15px;
  font-family: Roboto;
  margin-top: 30px;
  margin-bottom: 50px;
`;

export const SignButton = styled.button`
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 250px;
  border: none;
  border-radius: 5px;
  outline: none;
  color: ${color.primaryFont};
  cursor: pointer;
`;

export const SendCredButton = styled(SignButton)`
  margin-top: 80px;
  background-color: ${color.neutralDarkerGrey};
`;

export const SignupButton = styled(SignButton)`
  margin-top: 20px;
  background-color: ${color.neutralGrey};
`;

export const StepperButton = styled(SignButton)`
  margin-top: 80px;
  background-color: ${(props) => (props.disabled ? color.neutralGrey : '#3f51b5')};
  color: #ffffff;
`;
