import { ChangeEvent, FC, useState, useEffect } from 'react';
import CreateProduct from '../../../components/CreateProduct/CreateProduct';
import ProductsTable from '../../../components/ProductsTable/ProductsTable';
import { ReactComponent as BinSvg } from '../../../assets/bin.svg';
import {
  PageBox,
  Title,
  HeaderBox,
  DeleteCircle,
  LeftHeaderBox,
  Field,
  Glass,
} from './ProductPage.style';
import { DELETE_PRODUCTS, GET_PRODUCTS } from '../../../services/gqlHolder';
import { useQuery } from '@apollo/client';
import { Product } from '../../../graphQl/api_generated';
import { getProductsIdToDelete } from '../store/delete-products.store';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useMutation } from '@apollo/client';
import { displayAlertSelector } from '../../../store/alert.store';
import { Pagination } from '@material-ui/lab';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const QUANTITIES_PER_PAGE = [10, 30, 50, 100];

const ProductPage: FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [page, setPage] = useState<number>(1);
  const [productPerPage, setproductPerPage] = useState<number>(QUANTITIES_PER_PAGE[0]);
  const [researchField, setResearchField] = useState('');
  const productsIdToDelete = useRecoilValue(getProductsIdToDelete);
  const displayAlert = useSetRecoilState(displayAlertSelector);

  const { data, refetch } = useQuery(GET_PRODUCTS, {
    variables: {
      page,
      nbPerPage: productPerPage,
    },
  });

  const [deleteProductsMutation] = useMutation(DELETE_PRODUCTS, {
    onCompleted: () => {
      displayAlert({ severity: 'success', message: `Produits supprimés avec succes` });
      refetch();
    },
    onError: () =>
      displayAlert({
        severity: 'error',
        message: `Impossible de supprimer les produits`,
      }),
  });

  function handlePagination(event: ChangeEvent<unknown>, page: number): void {
    setPage(page);
    refetch({
      page,
      nbPerPage: productPerPage,
    });
  }

  useEffect(() => {
    if (data) {
      setProducts(
        data.getMyProducts.products.map((p: Product) => {
          const sellingPrice = parseFloat(p.sellingPrice).toFixed(2);
          return { ...p, sellingPrice };
        })
      );
    }
  }, [data, refetch]);

  const productsToDisplay = products.filter((product) =>
    product.name.toLowerCase().includes(researchField.toLowerCase())
  );

  const deleteProducts = () => {
    deleteProductsMutation({
      variables: {
        products: productsIdToDelete,
      },
    });
  };

  return (
    <PageBox>
      <Title>Gestion de mon inventaire</Title>
      <HeaderBox>
        <LeftHeaderBox>
          <CreateProduct refetchProducts={refetch} />
          {products.length > 0 && (
            <>
              <DeleteCircle onClick={deleteProducts}>
                <BinSvg />
              </DeleteCircle>
              <span>Devise: €</span>
            </>
          )}
          <h5 style={{ margin: '0 10px 0 30px' }}>Par Page</h5>
          <FormControl>
            <Select
              value={productPerPage}
              onChange={(event) => {
                setproductPerPage(parseInt(event.target.value as string));

                refetch({
                  page: page,
                  nbPerPage: parseInt(event.target.value as string),
                });
              }}>
              {QUANTITIES_PER_PAGE.map((q) => (
                <MenuItem key={q} value={q}>
                  {q}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </LeftHeaderBox>
        {products.length > 0 && (
          <div>
            <Field
              label="Rechercher"
              variant="outlined"
              size="small"
              onChange={(event) => setResearchField(event.target.value)}
            />
            <Glass />
          </div>
        )}
      </HeaderBox>

      <ProductsTable products={productsToDisplay} />

      {data && data.getMyProducts.maxPages > 1 && (
        <Pagination count={data.getMyProducts.maxPages} onChange={handlePagination} />
      )}
    </PageBox>
  );
};

export default ProductPage;
