import currency from 'currency.js';

export const formatPrice = (price: number | string) => {
  let newPrice = typeof price == 'string' ? parseFloat(price as string) : price;

  return currency(newPrice, {
    symbol: '€',
    separator: ' ',
    decimal: ',',
    pattern: '# !',
    negativePattern: '-# !',
  }).format();
};

export const formatFloatTwoDigits = (price: number) => {
  return parseFloat(
    currency(price, {
      separator: ' ',
      decimal: '.',
      pattern: '#',
      negativePattern: '-#',
    }).format()
  );
};
