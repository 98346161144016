import styled from 'styled-components';

export const FieldsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1fr);
  grid-column-gap: 20px;
  grid-row-gap: 5px;
`;

export const AddressBox = styled.div`
  grid-area: 2 / 1 / 3 / 4;
  display: grid;
`;
