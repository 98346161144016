import { FC, useState, useEffect } from 'react';
import { Button, Tabs, Tab } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  CREATE_SHOP_INFORMATION,
  GET_MY_SHOP_INFORMATION,
  UPDATE_SHOP_INFORMATIONS,
  GET_MY_EMAIL_INFORMATIONS,
  UPDATE_EMAIL_INFORMATIONS,
  CREATE_EMAIL_INFORMATIONS,
  CREATE_LOYALTY_PROGRAM,
  UPDATE_LOYALTY_PROGRAM,
} from '../../../services/gqlHolder';
import { useMutation, useQuery } from '@apollo/client';
import ShopInfosForm from '../../../components/TenantForm/ShopInfosForm/ShopInfosForm';
import ScheduleForm from '../../../components/TenantForm/ScheduleForm/ScheduleForm';
import SellingInfosForm from '../../../components/TenantForm/SellingInfosForm/SellingInfosForm';
import EmailShopInfosForm from '../../../components/EmailForm/EmailShopInfosForm/EmailShopInfosForm';
import EmailImagesForm from '../../../components/EmailForm/EmailImagesForm/EmailImagesForm';
import EmailContentInfosForm from '../../../components/EmailForm/EmailContentInfosForm/EmailContentInfosForm';
import ThemeForm from '../../../components/TenantForm/ThemeForm/ThemeForm';
import { MainBox, ButtonBox, LoaderBox } from './TenantPage.style';
import {
  EmailInformations,
  EmailInformationsUpdate,
  ShopInformations,
} from '../../../graphQl/api_generated';
import {
  defaultEmailInformations,
  defaultShopInformations,
  defaultLoyaltyProgram,
} from './common-data';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { displayAlertSelector } from '../../../store/alert.store';
import {
  invalidDaysInTenantOpenHoursStore,
  invalidDaysInTenantPickupHoursStore,
} from '../../../store/tenant-errors.store';

const TenantPage: FC = () => {
  const [displayedTabValue, setDisplayedTabValue] = useState(0);
  const displayAlert = useSetRecoilState(displayAlertSelector);

  const invalidDaysInTenantOpenHours = useRecoilValue(invalidDaysInTenantOpenHoursStore);
  const invalidDaysInTenantPickupHours = useRecoilValue(invalidDaysInTenantPickupHoursStore);
  const shopDataState = useState(defaultShopInformations);
  const [shopData, setShopData] = shopDataState;
  const loyaltyProgramState = useState(defaultLoyaltyProgram);
  const [loyaltyProgram, setLoyaltyProgram] = loyaltyProgramState;

  const { data: shopQueryData, loading: shopQueryLoading } = useQuery(GET_MY_SHOP_INFORMATION);
  const creatingNewShop = !shopQueryData;

  const emailDataState = useState<EmailInformations>(defaultEmailInformations);
  const [emailData, setEmailData] = emailDataState;
  const { data: emailQueryData, loading: emailQueryLoading } = useQuery(GET_MY_EMAIL_INFORMATIONS);
  const creatingNewEmail = !emailQueryData;

  useEffect(() => {
    if (shopQueryData) {
      const data: ShopInformations = JSON.parse(
        JSON.stringify(shopQueryData.getMyShopInformations)
      );

      data.loyaltyProgram && setLoyaltyProgram(data.loyaltyProgram);

      setShopData(data);
    }
  }, [shopQueryData, setShopData]);

  useEffect(() => {
    if (emailQueryData) {
      setEmailData(JSON.parse(JSON.stringify(emailQueryData.getMyEmailInformations)));
    }
  }, [emailQueryData, setEmailData]);

  const displaySuccessToaster = () =>
    displayAlert({ severity: 'success', message: 'Action réalisée avec succès' });

  const [createShopMutation] = useMutation(CREATE_SHOP_INFORMATION, {
    onCompleted: displaySuccessToaster,
  });

  const [updateShopMutation] = useMutation(UPDATE_SHOP_INFORMATIONS, {
    onCompleted: displaySuccessToaster,
  });

  const [createLoyaltyProgram] = useMutation(CREATE_LOYALTY_PROGRAM, {
    onCompleted: displaySuccessToaster,
  });

  const [updateLoyaltyProgram] = useMutation(UPDATE_LOYALTY_PROGRAM, {
    onCompleted: displaySuccessToaster,
  });

  const [createEmailMutation] = useMutation(CREATE_EMAIL_INFORMATIONS, {
    onCompleted: displaySuccessToaster,
  });

  const [updateEmailMutation] = useMutation(UPDATE_EMAIL_INFORMATIONS, {
    onCompleted: displaySuccessToaster,
  });

  const handleTabsChange = (newValue: number) => {
    setDisplayedTabValue(newValue);
  };

  const updateShop = () => {
    const { slug, loyaltyProgram, ...updatedShop } = { ...shopData };

    updateShopMutation({
      variables: {
        shop: {
          ...updatedShop,
        },
      },
    }).then((res) => {
      if (res.data) {
        setShopData(JSON.parse(JSON.stringify(res.data.updateMyShopInformations)));
      }
    });
  };

  const createShop = () => {
    const { id, ownerId, slug, ...createdShop } = { ...shopData };

    createShopMutation({
      variables: {
        shop: {
          ...createdShop,
        },
      },
    });
  };

  const updateEmail = () => {
    const updatedEmail: EmailInformationsUpdate = { ...emailData };

    updateEmailMutation({
      variables: {
        emailInfos: {
          ...updatedEmail,
        },
      },
    });
  };

  const createEmail = () => {
    const { shopId, ...createdEmail } = { ...emailData };

    createEmailMutation({
      variables: {
        emailInfos: {
          ...createdEmail,
        },
      },
    });
  };

  const handleSubmitShopForm = async () => {
    const loyaltyProgramExist = shopData.loyaltyProgram !== null;

    if (
      loyaltyProgram.isEnabled &&
      (loyaltyProgram.pointsName.length < 1 || loyaltyProgram.pointsDescription.length < 1)
    ) {
      displayAlert({ severity: 'error', message: "Pour activer le programme de fidelité, Donnez un nom aux points et ajoutez une description" });
      return;
    }

    if (loyaltyProgramExist) {
      await updateLoyaltyProgram({
        variables: {
          loyaltyProgram: loyaltyProgram,
        },
      });
    } else {
      await createLoyaltyProgram({
        variables: {
          loyaltyProgram: loyaltyProgram,
        },
      });
    }

    creatingNewShop ? createShop() : updateShop();
  };

  return (
    <>
      {shopQueryLoading || emailQueryLoading ? (
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      ) : (
        <>
          <Tabs
            value={displayedTabValue}
            onChange={(_, value) => handleTabsChange(value)}
            indicatorColor="primary"
            textColor="primary"
            centered>
            <Tab label="Formulaire de la boutique " />
            <Tab label="Formulaire des emails" />
          </Tabs>
          {displayedTabValue === 0 ? (
            <MainBox>
              <ShopInfosForm shopDataState={shopDataState} />
              <ScheduleForm shopDataState={shopDataState} />
              <SellingInfosForm
                shopDataState={shopDataState}
                loyaltyProgramState={loyaltyProgramState}
              />
              <ThemeForm shopDataState={shopDataState} />

              <ButtonBox>
                <Button
                  variant="contained"
                  disabled={
                    !!invalidDaysInTenantOpenHours.length || !!invalidDaysInTenantPickupHours.length
                  }
                  onClick={handleSubmitShopForm}>
                  {creatingNewShop ? 'Créer mon shop' : 'Modifier mes informations'}
                </Button>
              </ButtonBox>
            </MainBox>
          ) : (
            <MainBox>
              <EmailShopInfosForm emailDataState={emailDataState} />
              <EmailImagesForm emailDataState={emailDataState} />
              <EmailContentInfosForm emailDataState={emailDataState} />

              <ButtonBox>
                <Button
                  variant="contained"
                  onClick={() => (creatingNewEmail ? createEmail() : updateEmail())}>
                  {creatingNewEmail
                    ? 'Créer mes informations de mail'
                    : 'Modifier mes informations de mail'}
                </Button>
              </ButtonBox>
            </MainBox>
          )}
        </>
      )}
    </>
  );
};

export default TenantPage;
