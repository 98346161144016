export const formatUTCTime = (date: Date): string => {
  const hoursToDisplay = new Date(date).getUTCHours();
  const minutesToDisplay = new Date(date).getUTCMinutes();

  const formattedTime = `${hoursToDisplay.toString().padStart(2, '0')}:${minutesToDisplay
    .toString()
    .padStart(2, '0')}`;

  return formattedTime;
};
