import { BrowserRouter, Route } from 'react-router-dom';
import BackOfficeLayout from './modules/backoffice/BackOfficeLayout';
import { ApolloProvider } from '@apollo/client';
import { client } from './services/apollo';
import AuthLayout from './modules/auth/AuthLayout';
import Alert from './components/Alert/Alert';
import { RecoilRoot } from 'recoil';

function App(): JSX.Element {
  return (
    <RecoilRoot>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Route path="/" component={AuthLayout} />
          <Route path="/backoffice" component={BackOfficeLayout} />
        </BrowserRouter>
      </ApolloProvider>

      <Alert />
    </RecoilRoot>
  );
}

export default App;
