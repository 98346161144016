import { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import OrderPage from './OrderPage/OrderPage';
import UploadPage from './UploadPage/UploadPage';
import TenantPage from './TenantPage/TenantPage';
import ProductPage from './ProductPage/ProductPage';
import { isValidSession } from '../../utils/userChecks';
import Header from '../../components/Header/Header';
import Navbar from './Navbar/Navbar';
import { FullPage, ContentBox, RightBox } from './BackOfficeLayout.styles';

function BackOfficeLayout(): JSX.Element {
  const history = useHistory();

  useEffect(() => {
    if (!isValidSession()) {
      history.replace('/');
    }
  }, [history]);

  return (
    <FullPage>
      <ContentBox>
        <Navbar />
        <RightBox>
          <Route exact path="/backoffice/orders" component={OrderPage} />
          <Route exact path="/backoffice/upload" component={UploadPage} />
          <Route exact path="/backoffice/tenant" component={TenantPage} />
          <Route exact path="/backoffice/products" component={ProductPage} />
        </RightBox>
      </ContentBox>
      <Header />
    </FullPage>
  );
}

export default BackOfficeLayout;
