import { useMutation } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import { CREATE_PRODUCT } from '../../services/gqlHolder';
import { ProductCreate, WholesaleMeasureUnitProduct } from '../../graphQl/api_generated';
import { Modal, Select, MenuItem } from '@material-ui/core';
import {
  ModalContainer,
  GridContainer,
  FieldContainer,
  ButtonContainer,
  Title,
  SubTitle,
  InputField,
  SmallField,
  SelectField,
  CenteredRow,
  Label,
  DynamicText,
  SubmitButton,
  ModalButton,
  ExitCross,
} from './CreateProduct.style';
import {
  isJarNeeded as isJarNeededFunction,
  getDisplayedQuantityDefault,
  getDisplayedMeasureUnit,
  isDisplayedQuantityEditable,
  getIncrement,
  getWholesalePrice,
  getDisplayedPrice,
  isWholesaleMeasureUnitEditable,
} from '../../utils/computeProduct';
import { getShortUnit } from '../../utils/units';
import { CustomSwitch } from '../ProductsTable/ProductsTable.style';

const unitArray = [
  { id: WholesaleMeasureUnitProduct.Litre, label: 'L' },
  { id: WholesaleMeasureUnitProduct.Kilogramme, label: 'Kg' },
  { id: WholesaleMeasureUnitProduct.Unit, label: 'u' },
];

interface CreateProductProps {
  refetchProducts: () => void;
}

const CreateProduct: FC<CreateProductProps> = (props: CreateProductProps) => {
  const { refetchProducts } = props;

  const [category, setCategory] = useState<string>('');
  const [subcategory, setSubcategory] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);
  const [sellingMeasureUnit, setSellingMeasureUnit] = useState<WholesaleMeasureUnitProduct>(
    WholesaleMeasureUnitProduct.Unit
  );
  const [wholesaleMeasureUnit, setWholesaleMeasureUnit] = useState<WholesaleMeasureUnitProduct>(
    WholesaleMeasureUnitProduct.Unit
  );
  const [displayedQuantity, setDisplayedQuantity] = useState<number>(0);
  const [capacity] = useState<number>(0);
  const [pictureUrl, setPictureUrl] = useState<string>('');
  const [quantityIncrement, setQuantityIncrement] = useState<number>(1);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [createProduct] = useMutation(CREATE_PRODUCT);

  const productToCreate: ProductCreate = {
    category,
    subcategory,
    name,
    isAvailable,
    sellingPrice,
    sellingMeasureUnit,
    capacity,
    wholesaleMeasureUnit,
    displayedQuantity,
    quantityIncrement,
    pictureUrl,
    loyaltyPoints,
    isDraft,
  };

  useEffect(() => {
    setQuantityIncrement(getIncrement(sellingMeasureUnit));
  }, [sellingMeasureUnit]);

  const onSubmit = () => {
    createProduct({
      variables: {
        products: [productToCreate],
      },
    });
    refetchProducts();
    setIsModalVisible(false);
  };

  const isJarNeeded = isJarNeededFunction({ sellingMeasureUnit, wholesaleMeasureUnit });

  const modalBody = (
    <ModalContainer>
      <Title>Ajouter un produit</Title>
      <SubTitle>Informations produit</SubTitle>
      <InputField
        id={'name'}
        label={'Nom du produit'}
        onChange={(event) => setName(event.target.value)}
      />
      <GridContainer>
        <InputField
          id={'category'}
          label={'Catégorie'}
          onChange={(event) => setCategory(event.target.value)}
        />
        <InputField
          id={'subCategory'}
          label={'Sous-catégorie'}
          onChange={(event) => setSubcategory(event.target.value)}
        />
      </GridContainer>

      <SubTitle>Informations de vente</SubTitle>
      <GridContainer>
        <InputField
          id={'sellingPrice'}
          type="number"
          label={'Prix de vente'}
          onChange={(event) => {
            if (isNaN(Number(event))) {
              setSellingPrice(Number(event.target.value));
            }
          }}
        />
        <FieldContainer>
          <Label>Unité de vente</Label>
          <Select
            id="sellingMeasureUnit"
            placeholder={sellingMeasureUnit}
            value={sellingMeasureUnit}
            onChange={(event) => {
              setWholesaleMeasureUnit(event.target.value as WholesaleMeasureUnitProduct);
              setSellingMeasureUnit(event.target.value as WholesaleMeasureUnitProduct);
            }}>
            {unitArray.map((e, index) => {
              return (
                <MenuItem key={index} value={e.id}>
                  {e.label}
                </MenuItem>
              );
            })}
          </Select>
        </FieldContainer>
        <FieldContainer>
          <Label>Nécessite un bocal</Label>
          <CustomSwitch checked={isJarNeeded} color="default" />
        </FieldContainer>
      </GridContainer>

      <SubTitle>Informations d'affichage</SubTitle>
      <GridContainer>
        <FieldContainer>
          <Label>Prix au kg / L</Label>
          <DynamicText>
            {getWholesalePrice({
              wholesaleMeasureUnit,
              sellingMeasureUnit,
              sellingPrice,
              capacity,
            })}
            /kg
          </DynamicText>
        </FieldContainer>
        <FieldContainer>
          <Label>Unité de mesure</Label>

          {isWholesaleMeasureUnitEditable(sellingMeasureUnit) ? (
            <SelectField
              id="wholesaleMeasureUnit"
              placeholder={sellingMeasureUnit}
              value={wholesaleMeasureUnit}
              onChange={(event) =>
                setWholesaleMeasureUnit(event.target.value as WholesaleMeasureUnitProduct)
              }>
              {unitArray.map((e, index) => {
                return (
                  <MenuItem key={index} value={e.id}>
                    {e.label}
                  </MenuItem>
                );
              })}
            </SelectField>
          ) : (
            <DynamicText>{getShortUnit(sellingMeasureUnit)}</DynamicText>
          )}
        </FieldContainer>
        <FieldContainer>
          <Label>Prix affiché</Label>
          <DynamicText>
            {getDisplayedPrice({ sellingMeasureUnit, sellingPrice, displayedQuantity })}
          </DynamicText>
        </FieldContainer>
        <FieldContainer>
          <Label>Quantité affichée</Label>
          <CenteredRow>
            <SmallField
              disabled={!isDisplayedQuantityEditable(sellingMeasureUnit)}
              type="number"
              defaultValue={getDisplayedQuantityDefault(sellingMeasureUnit)}
              value={displayedQuantity}
              id={'displayQuantity'}
              onChange={(event) => {
                if (isNaN(Number(event))) {
                  setDisplayedQuantity(Number(event.target.value));
                }
              }}
            />
            {getShortUnit(getDisplayedMeasureUnit(sellingMeasureUnit))}
          </CenteredRow>
        </FieldContainer>
        <FieldContainer>
          <Label>Incrément</Label>
          <DynamicText>
            <CenteredRow>
              <SmallField
                type="number"
                defaultValue={quantityIncrement}
                id={'quantityIncrement'}
                onChange={(event) => setQuantityIncrement(parseInt(event.target.value))}
              />
              {getShortUnit(getDisplayedMeasureUnit(sellingMeasureUnit))}
            </CenteredRow>
          </DynamicText>
        </FieldContainer>

        <InputField
          id="loyaltyPoints"
          type="number"
          label={'Nombre de points fidélité'}
          onChange={(event) => {
            if (isNaN(Number(event))) {
              setLoyaltyPoints(Number(event.target.value));
            }
          }}
        />
      </GridContainer>

      <SubTitle>Informations supplémentaires</SubTitle>
      <GridContainer>
        <FieldContainer>
          <Label>Disponibilité</Label>
          <CustomSwitch
            checked={isAvailable}
            onChange={(event) => setIsAvailable(event.target.checked)}
            color="default"
          />
        </FieldContainer>
        <FieldContainer>
          <Label>En vente</Label>
          <CustomSwitch
            checked={!isDraft}
            onChange={(event) => setIsDraft(!event.target.checked)}
            color="default"
          />
        </FieldContainer>
      </GridContainer>
      <InputField
        label="URL de l'image"
        onChange={(event) => {
          setPictureUrl(event.target.value);
        }}
      />

      <ButtonContainer>
        <SubmitButton
          variant="contained"
          onClick={() => {
            onSubmit();
          }}>
          Valider
        </SubmitButton>
      </ButtonContainer>

      <ExitCross onClick={() => setIsModalVisible(false)} />
    </ModalContainer>
  );

  return (
    <>
      <ModalButton onClick={() => setIsModalVisible(true)}>Ajouter un produit + </ModalButton>
      <Modal open={isModalVisible} onClose={() => setIsModalVisible(false)}>
        {modalBody}
      </Modal>
    </>
  );
};

export default CreateProduct;
