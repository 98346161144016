import { FC, useState } from 'react';
import OrderStatusDropdown from './OrderStatusDropdown';
import moment from 'moment';
import { Order } from '../../graphQl/api_generated';
import { color } from '../../theme';
import { Link, Cell, Row, ProductsTitle, ProductsRow } from './OrderRow.style';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { formatPrice } from '../../utils/price';
import { orderTableColumns } from './OrderTable';
import { formatUnit } from '../../utils/units';
moment.locale();

interface OrderRowProps {
  order: Order;
  index: number;
}

const productsColumns = ['Produit', 'Quantité', 'Prix'];

const OrderRow: FC<OrderRowProps> = (props) => {
  const { order, index }: OrderRowProps = props;

  const [expanded, setExpanded] = useState<boolean>(false);

  const pickupDate = order.pickupDate
    ? moment(order.pickupDate).format('DD-MM-YYYY')
    : 'Non renseigné';
  const pickupHour = order.pickupDate ? moment(order.pickupDate).format('HH:mm') : 'Non renseigné';
  const createdAt = order.createdAt
    ? moment(order.createdAt).format('DD-MM-YYYY')
    : 'Non renseigné';

  const products = order.products;

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      style={{
        backgroundColor: index % 2 === 0 ? 'transparent' : color.primaryLighter,
      }}
    >
      <AccordionSummary
        style={{
          flexDirection: 'row-reverse',
          padding: 0,
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Row>
          <Cell percentWidth={orderTableColumns[0].percentWidth}>{`${
            order.firstname
          } ${order.lastname.toUpperCase()}`}</Cell>
          <Cell percentWidth={orderTableColumns[1].percentWidth}>{pickupDate}</Cell>
          <Cell percentWidth={orderTableColumns[2].percentWidth}>{pickupHour}</Cell>
          <Cell percentWidth={orderTableColumns[3].percentWidth}>{order.phone}</Cell>
          <Cell percentWidth={orderTableColumns[4].percentWidth}>
            <Link href={`mailto:${order.email}`}>{order.email}</Link>
          </Cell>
          <Cell percentWidth={orderTableColumns[5].percentWidth}>{createdAt}</Cell>
          <Cell percentWidth={orderTableColumns[6].percentWidth}>
            <OrderStatusDropdown order={order} />
          </Cell>
          <Cell percentWidth={orderTableColumns[7].percentWidth}>
            {order.hasBag ? 'Oui' : 'Non'}
          </Cell>
        </Row>
      </AccordionSummary>
      <AccordionDetails style={{ padding: '0 40px 40px 40px', display: 'block' }}>
        <ProductsTitle>Commande</ProductsTitle>

        <ProductsRow isHeaderRow>
          {productsColumns.map((column) => (
            <div key={column}>{column}</div>
          ))}
        </ProductsRow>

        {products.map((product) => (
          <ProductsRow>
            <div>{product.name}</div>
            <div>
              {product.quantity} {formatUnit(product.unit)}
            </div>
            <div>{formatPrice(product.price)}</div>
          </ProductsRow>
        ))}

        <ProductsRow isTotalRow>
          <div>Prix Total</div>
          <div />
          <div>{formatPrice(order.amount)}</div>
        </ProductsRow>
      </AccordionDetails>
    </Accordion>
  );
};

export default OrderRow;
