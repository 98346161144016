import { FC } from 'react';
import { StyledDialog, DialogTitleBox, DialogActionBox, Button } from './ConfirmationModal.style';
import { Order } from '../../graphQl/api_generated';
import { UPDATE_ORDER } from '../../services/gqlHolder';
import { useMutation } from '@apollo/client';

type ConfirmationModalProps = {
  setVisibility: (newValue: boolean) => void;
  visibility: boolean;
  newStatus: string;
  order: Order;
  setStatus: (newStatus: string) => void;
};

const ConfirmationModal: FC<ConfirmationModalProps> = (props) => {
  const { setVisibility, visibility, setStatus, newStatus, order }: ConfirmationModalProps = props;

  const [updateOrder] = useMutation(UPDATE_ORDER);

  const handleClose = () => {
    setVisibility(false);
  };

  const onCancel = () => {
    handleClose();
  };

  const onConfirm = () => {
    setStatus(newStatus);
    const { ...updatedOrder } = order;

    updatedOrder.updatedAt = new Date();
    updatedOrder.status = newStatus;
    updatedOrder.products = []; // TODO: we'll fix this when we'll change the update_order to update_status_order on back

    updateOrder({ variables: { order: updatedOrder } });
    handleClose();
  };

  return (
    <StyledDialog open={visibility} onClose={onCancel}>
      <DialogTitleBox id="alert-dialog-title">Avez-vous terminé la commande ?</DialogTitleBox>
      <DialogActionBox>
        <Button onClick={onConfirm}>Oui</Button>
        <Button cancelButton onClick={onCancel} autoFocus>
          Annuler
        </Button>
      </DialogActionBox>
    </StyledDialog>
  );
};
export default ConfirmationModal;
