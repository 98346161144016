import styled from 'styled-components';
import { color } from '../../../theme';

export const MainBox = styled.div`
  margin: 5vw;
  padding: 0px 20px 20px 20px;
  border: solid;
  border-radius: 10px;
  border-width: 2px;
  border-color: ${color.primary};
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 55px;
`;

export const LoaderBox = styled.div`
  margin-top: 20%;
  display: flex;
  justify-content: center;
`;
