// import styled from 'styled-components';
import { MenuItem, Select, withStyles } from '@material-ui/core';

export const SelectBox = withStyles({
  root: {
    fontSize: 14,
    padding: '10px',
    width: 70,
  },
})(Select);

export const ListItem = withStyles({
  root: {
    fontSize: 14,
  },
})(MenuItem);
