import styled from 'styled-components';

export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;

  p {
    font-weight: bold;
  }
`;
