import {
  Credentials,
  EmailInformations,
  LoyaltyProgram,
  ShopInformations,
  Week,
} from '../../../graphQl/api_generated';
import defaultShopImage from '../../../assets/default-shop-image.png';

const defaultWeek: Week = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
};

export const defaultShopInformations: ShopInformations = {
  id: '',
  ownerId: [],
  name: '',
  slug: '',
  pickupHours: { week: defaultWeek },
  phone: '',
  pickupDelayMinutes: 60,
  pickupTimeIncrementMinutes: 15,
  openHours: { week: defaultWeek },
  theme: {
    primaryLighter: undefined,
    primaryFontColor: undefined,
    contrast: undefined,
    primary: undefined,
  },
  address: '',
  kraftBagsPrice: 0.1,
  email: '',
  defaultCurrency: 'EUR',
  facebookLink: undefined,
  twitterLink: undefined,
  instagramLink: undefined,
  headerDecorationUrl: undefined,
  categoriesDecorationUrl: undefined,
  backgroundDecorationUrl: undefined,
  validationDecorationUrl: undefined,
  logoUrl: defaultShopImage,
  orderSummaryDecorationUrl: undefined,
  cartDecorationUrl: undefined,
  placeholderUrl: undefined,
  primaryFont: 'Roboto',
  secondaryFont: 'Lato',
};

export const defaultLoyaltyProgram: LoyaltyProgram = {
  isEnabled: false,
  pointsDescription: '',
  pointsName: '',
};

export const defaultEmailInformations: EmailInformations = {
  shopId: [],
  contactEmail: '',
  orderReceptionEmail: '',
  phoneNumber: '',
  address: '',
  bannerUrl: '',
  logoUrl: '',
  decorationUrl: '',
  introductionOrderReceivedText: '',
  introductionOrderReadyText: '',
  productText: '',
  containerText: '',
};

export const defaultCredentials: Credentials = {
  email: '',
  password: '',
};
