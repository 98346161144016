import { WholesaleMeasureUnitProduct, DisplayedUnit } from '../graphQl/api_generated';

const boValues = {
  LITRE: 'L',
  MILLILITRE: 'mL',
  GRAMME: 'g',
  KILOGRAMME: 'kg',
  UNIT: 'u',
};

export const getShortUnit = (unit: WholesaleMeasureUnitProduct | DisplayedUnit) =>
  unit in boValues ? boValues[unit] : '';

// TODO: This function must be replaced by getShortUnit
export const formatUnit = (unit: string) => {
  const unitToFormat = unit.toLocaleLowerCase();

  if (unitToFormat === 'l') {
    return 'L';
  } else if (unitToFormat === 'ml') {
    return 'mL';
  }

  return unitToFormat;
};
