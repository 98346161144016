import styled from 'styled-components';
import { ReactComponent as RemoveSvg } from '../../../assets/remove-btn.svg';
import { withStyles, TextField } from '@material-ui/core';

export const ComponentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0px;
`;

export const MiddleSpace = styled.div`
  width: 20px;
`;

export const RemoveButtonBox = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
`;

export const RemoveButton = styled(RemoveSvg)`
  width: 20px;
  height: 20px;
  margin-top: 8px;
`;

export const Field = withStyles({
  root: {
    width: 200,
  },
})(TextField);
