import { getToken } from './../utils/userChecks';
import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';

const BACK_END = process.env.REACT_APP_BACKEND_URL || window.env.BACKEND_URL;
const BACK_END_BACKSHOP_URL = `${BACK_END}/backshop`;

const authLink = setContext((_, { headers }) => {
  const tokenInfo = getToken();

  return {
    headers: {
      ...headers,
      token: tokenInfo?.token,
    },
  };
});

const additiveLink = from([
  authLink,
  createUploadLink({ uri: BACK_END_BACKSHOP_URL }),
  createHttpLink({ uri: BACK_END_BACKSHOP_URL }),
]);

export const client = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
