import styled from 'styled-components';

export const FullPage = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const ContentBox = styled.div`
  display: flex;
  height: calc(100vh - 100px);
`;

export const RightBox = styled.div`
  width: 100%;
  margin-left: 187px;
  margin-top: 100px;
`;
