import styled from 'styled-components';

export const FieldsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
`;

export const ScheduleBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
