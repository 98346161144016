import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
} from '@material-ui/core';
import { tableContainerStyle, NoProducts } from './ProductsTable.style';
import { Product } from '../../graphQl/api_generated';
import ProductRow from './ProductRow';

const columns = [
  '',
  'Catégorie',
  'Sous-catégorie',
  'Nom produit',
  'Disponibilité',
  'Prix de vente',
  'Unité de vente',
  'En vente',
  'Nombre de points fidélité',
  'Necessite bocal',
  'Prix au kg/L',
  'Unité de mesure',
  'Prix Affiché',
  'Quantité Affichée',
  'Incrément',
  'Image URL',
];

interface ProductsTableProps {
  products: Product[];
}

const ProductsTable = (props: ProductsTableProps) => {
  const { products } = props;

  return (
    <TableContainer style={tableContainerStyle} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <ProductRow key={product.id} product={product} />
          ))}
        </TableBody>
      </Table>
      {!products.length && (
        <TableRow>
          <NoProducts>Aucun produits...</NoProducts>
        </TableRow>
      )}
    </TableContainer>
  );
};

export default ProductsTable;
