export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  DateTime: any;
  Price: any;
  UUID: any;
  Upload: any;
};

export type ColorTheme = {
  __typename?: 'ColorTheme';
  contrast?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['String']>;
  primaryFontColor?: Maybe<Scalars['String']>;
  primaryLighter?: Maybe<Scalars['String']>;
};

export type ColorThemeInput = {
  contrast?: InputMaybe<Scalars['String']>;
  primary?: InputMaybe<Scalars['String']>;
  primaryFontColor?: InputMaybe<Scalars['String']>;
  primaryLighter?: InputMaybe<Scalars['String']>;
};

export type Credentials = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export enum DisplayedUnit {
  Gramme = 'GRAMME',
  Millilitre = 'MILLILITRE',
  Unit = 'UNIT',
}

export type EmailInformations = {
  __typename?: 'EmailInformations';
  address: Scalars['String'];
  bannerUrl: Scalars['String'];
  contactEmail: Scalars['String'];
  containerText?: Maybe<Scalars['String']>;
  decorationUrl?: Maybe<Scalars['String']>;
  introductionOrderReadyText: Scalars['String'];
  introductionOrderReceivedText: Scalars['String'];
  logoUrl: Scalars['String'];
  orderReceptionEmail: Scalars['String'];
  phoneNumber: Scalars['String'];
  productText?: Maybe<Scalars['String']>;
  shopId: Array<Scalars['Int']>;
};

export type EmailInformationsCreate = {
  address: Scalars['String'];
  bannerUrl: Scalars['String'];
  contactEmail: Scalars['String'];
  containerText?: InputMaybe<Scalars['String']>;
  decorationUrl?: InputMaybe<Scalars['String']>;
  introductionOrderReadyText: Scalars['String'];
  introductionOrderReceivedText: Scalars['String'];
  logoUrl: Scalars['String'];
  orderReceptionEmail: Scalars['String'];
  phoneNumber: Scalars['String'];
  productText?: InputMaybe<Scalars['String']>;
};

export type EmailInformationsUpdate = {
  address: Scalars['String'];
  bannerUrl: Scalars['String'];
  contactEmail: Scalars['String'];
  containerText?: InputMaybe<Scalars['String']>;
  decorationUrl?: InputMaybe<Scalars['String']>;
  introductionOrderReadyText: Scalars['String'];
  introductionOrderReceivedText: Scalars['String'];
  logoUrl: Scalars['String'];
  orderReceptionEmail: Scalars['String'];
  phoneNumber: Scalars['String'];
  productText?: InputMaybe<Scalars['String']>;
  shopId: Array<Scalars['Int']>;
};

export type FileInfo = {
  __typename?: 'FileInfo';
  name: Scalars['String'];
  url: Scalars['String'];
};

export type FileList = {
  __typename?: 'FileList';
  files: Array<FileInfo>;
};

export type LoyaltyProgram = {
  __typename?: 'LoyaltyProgram';
  isEnabled: Scalars['Boolean'];
  pointsDescription: Scalars['String'];
  pointsName: Scalars['String'];
};

export type LoyaltyProgramInput = {
  isEnabled: Scalars['Boolean'];
  pointsDescription: Scalars['String'];
  pointsName: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createLoyaltyProgram: LoyaltyProgram;
  createMyEmailInformations: EmailInformations;
  createMyProducts: Array<Product>;
  createMyShopInformations: ShopInformations;
  deleteFile: Scalars['Boolean'];
  deleteMyProducts: Array<Scalars['UUID']>;
  login: UserAndSession;
  signup: User;
  updateLoyaltyProgram: LoyaltyProgram;
  updateMyEmailInformations: EmailInformations;
  updateMyProduct: Product;
  updateMyShopInformations: ShopInformations;
  updateOrder: Order;
  uploadFile: Scalars['String'];
};

export type MutationCreateLoyaltyProgramArgs = {
  loyaltyProgram: LoyaltyProgramInput;
};

export type MutationCreateMyEmailInformationsArgs = {
  emailInformations: EmailInformationsCreate;
};

export type MutationCreateMyProductsArgs = {
  products: Array<ProductCreate>;
};

export type MutationCreateMyShopInformationsArgs = {
  shopInformations: ShopInformationsCreate;
};

export type MutationDeleteFileArgs = {
  filename: Scalars['String'];
};

export type MutationDeleteMyProductsArgs = {
  products: Array<Scalars['UUID']>;
};

export type MutationLoginArgs = {
  credentials: Credentials;
};

export type MutationSignupArgs = {
  credentials: Credentials;
};

export type MutationUpdateLoyaltyProgramArgs = {
  loyaltyProgram: LoyaltyProgramInput;
};

export type MutationUpdateMyEmailInformationsArgs = {
  emailInformations: EmailInformationsUpdate;
};

export type MutationUpdateMyProductArgs = {
  product: ProductUpdate;
};

export type MutationUpdateMyShopInformationsArgs = {
  shopInformations: ShopInformationsUpdate;
};

export type MutationUpdateOrderArgs = {
  orderUpdate: OrderUpdate;
};

export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};

export type OpenHours = {
  __typename?: 'OpenHours';
  week: Week;
};

export type OpenHoursInput = {
  week: WeekInput;
};

export type Order = {
  __typename?: 'Order';
  amount: Scalars['Price'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  hasBag: Scalars['Boolean'];
  lastname: Scalars['String'];
  phone: Scalars['String'];
  pickupDate: Scalars['DateTime'];
  products: Array<OrderProduct>;
  reference: Scalars['UUID'];
  shopId: Scalars['UUID'];
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OrderProduct = {
  __typename?: 'OrderProduct';
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  orderReference: Scalars['UUID'];
  price: Scalars['Price'];
  productId: Scalars['UUID'];
  quantity: Scalars['Int'];
  unit: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OrderProductInput = {
  price: Scalars['Price'];
  quantity: Scalars['Int'];
  reference: Scalars['String'];
  unit: DisplayedUnit;
};

export type OrderUpdate = {
  amount: Scalars['BigDecimal'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  hasBag: Scalars['Boolean'];
  lastname: Scalars['String'];
  phone: Scalars['String'];
  pickupDate: Scalars['DateTime'];
  products: Array<OrderProductInput>;
  reference: Scalars['UUID'];
  shopId: Scalars['UUID'];
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PaginationOrders = {
  __typename?: 'PaginationOrders';
  maxPages?: Maybe<Scalars['Int']>;
  orders: Array<Order>;
};

export type PaginationProducts = {
  __typename?: 'PaginationProducts';
  maxPages?: Maybe<Scalars['Int']>;
  products: Array<Product>;
};

export type Product = {
  __typename?: 'Product';
  capacity: Scalars['Int'];
  category: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayedMeasureUnit: ProductDisplayedUnit;
  displayedPrice: Scalars['Price'];
  displayedQuantity: Scalars['Int'];
  id: Scalars['UUID'];
  isAvailable: Scalars['Boolean'];
  isDraft: Scalars['Boolean'];
  loyaltyPoints: Scalars['Int'];
  name: Scalars['String'];
  needsJar: Scalars['Boolean'];
  pictureUrl?: Maybe<Scalars['String']>;
  quantityIncrement: Scalars['Int'];
  sellingMeasureUnit: WholesaleMeasureUnitProduct;
  sellingPrice: Scalars['Price'];
  shopId: Scalars['UUID'];
  subcategory: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  wholesaleMeasureUnit: WholesaleMeasureUnitProduct;
  wholesalePrice?: Maybe<Scalars['Price']>;
};

export type ProductCreate = {
  capacity: Scalars['Int'];
  category: Scalars['String'];
  displayedQuantity: Scalars['Int'];
  isAvailable: Scalars['Boolean'];
  isDraft: Scalars['Boolean'];
  loyaltyPoints: Scalars['Int'];
  name: Scalars['String'];
  pictureUrl?: InputMaybe<Scalars['String']>;
  quantityIncrement: Scalars['Int'];
  sellingMeasureUnit: WholesaleMeasureUnitProduct;
  sellingPrice: Scalars['Price'];
  subcategory: Scalars['String'];
  wholesaleMeasureUnit: WholesaleMeasureUnitProduct;
};

export enum ProductDisplayedUnit {
  Gramme = 'GRAMME',
  Millilitre = 'MILLILITRE',
  Unit = 'UNIT',
}

export type ProductUpdate = {
  capacity: Scalars['Int'];
  category: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  displayedQuantity: Scalars['Int'];
  id: Scalars['UUID'];
  isAvailable: Scalars['Boolean'];
  isDraft: Scalars['Boolean'];
  loyaltyPoints: Scalars['Int'];
  name: Scalars['String'];
  pictureUrl?: InputMaybe<Scalars['String']>;
  quantityIncrement: Scalars['Int'];
  sellingMeasureUnit: WholesaleMeasureUnitProduct;
  sellingPrice: Scalars['Price'];
  shopId: Scalars['UUID'];
  subcategory: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  wholesaleMeasureUnit: WholesaleMeasureUnitProduct;
};

export type Query = {
  __typename?: 'Query';
  getMyEmailInformations: EmailInformations;
  getMyFileList: FileList;
  getMyOrders: PaginationOrders;
  getMyProducts: PaginationProducts;
  getMyShopInformations: ShopInformations;
};

export type QueryGetMyOrdersArgs = {
  nbPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetMyProductsArgs = {
  nbPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type Session = {
  __typename?: 'Session';
  createdAt: Scalars['DateTime'];
  refreshToken: Scalars['String'];
  refreshTokenExpiresAt: Scalars['DateTime'];
  token: Scalars['String'];
  tokenExpiresAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['UUID'];
};

export type ShopInformations = {
  __typename?: 'ShopInformations';
  address: Scalars['String'];
  backgroundDecorationUrl?: Maybe<Scalars['String']>;
  cartDecorationUrl?: Maybe<Scalars['String']>;
  categoriesDecorationUrl?: Maybe<Scalars['String']>;
  defaultCurrency: Scalars['String'];
  email: Scalars['String'];
  facebookLink?: Maybe<Scalars['String']>;
  headerDecorationUrl?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  instagramLink?: Maybe<Scalars['String']>;
  kraftBagsPrice: Scalars['BigDecimal'];
  logoUrl?: Maybe<Scalars['String']>;
  loyaltyProgram?: Maybe<LoyaltyProgram>;
  name: Scalars['String'];
  openHours: OpenHours;
  orderSummaryDecorationUrl?: Maybe<Scalars['String']>;
  ownerId: Scalars['UUID'];
  phone: Scalars['String'];
  pickupDelayMinutes: Scalars['Int'];
  pickupHours: OpenHours;
  pickupTimeIncrementMinutes: Scalars['Int'];
  placeholderUrl?: Maybe<Scalars['String']>;
  primaryFont?: Maybe<Scalars['String']>;
  secondaryFont?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  theme: ColorTheme;
  twitterLink?: Maybe<Scalars['String']>;
  validationDecorationUrl?: Maybe<Scalars['String']>;
};

export type ShopInformationsCreate = {
  address: Scalars['String'];
  backgroundDecorationUrl?: InputMaybe<Scalars['String']>;
  cartDecorationUrl?: InputMaybe<Scalars['String']>;
  categoriesDecorationUrl?: InputMaybe<Scalars['String']>;
  defaultCurrency: Scalars['String'];
  email: Scalars['String'];
  facebookLink?: InputMaybe<Scalars['String']>;
  headerDecorationUrl?: InputMaybe<Scalars['String']>;
  instagramLink?: InputMaybe<Scalars['String']>;
  kraftBagsPrice: Scalars['BigDecimal'];
  logoUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  openHours: OpenHoursInput;
  orderSummaryDecorationUrl?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  pickupDelayMinutes: Scalars['Int'];
  pickupHours: OpenHoursInput;
  pickupTimeIncrementMinutes: Scalars['Int'];
  placeholderUrl?: InputMaybe<Scalars['String']>;
  primaryFont?: InputMaybe<Scalars['String']>;
  secondaryFont?: InputMaybe<Scalars['String']>;
  theme: ColorThemeInput;
  twitterLink?: InputMaybe<Scalars['String']>;
  validationDecorationUrl?: InputMaybe<Scalars['String']>;
};

export type ShopInformationsUpdate = {
  address: Scalars['String'];
  backgroundDecorationUrl?: InputMaybe<Scalars['String']>;
  cartDecorationUrl?: InputMaybe<Scalars['String']>;
  categoriesDecorationUrl?: InputMaybe<Scalars['String']>;
  defaultCurrency: Scalars['String'];
  email: Scalars['String'];
  facebookLink?: InputMaybe<Scalars['String']>;
  headerDecorationUrl?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  instagramLink?: InputMaybe<Scalars['String']>;
  kraftBagsPrice: Scalars['BigDecimal'];
  logoUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  openHours: OpenHoursInput;
  orderSummaryDecorationUrl?: InputMaybe<Scalars['String']>;
  ownerId: Scalars['UUID'];
  phone: Scalars['String'];
  pickupDelayMinutes: Scalars['Int'];
  pickupHours: OpenHoursInput;
  pickupTimeIncrementMinutes: Scalars['Int'];
  placeholderUrl?: InputMaybe<Scalars['String']>;
  primaryFont?: InputMaybe<Scalars['String']>;
  secondaryFont?: InputMaybe<Scalars['String']>;
  theme: ColorThemeInput;
  twitterLink?: InputMaybe<Scalars['String']>;
  validationDecorationUrl?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['UUID'];
  isDeleted: Scalars['Boolean'];
  roles: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type UserAndSession = {
  __typename?: 'UserAndSession';
  session: Session;
  user: User;
};

export type Week = {
  __typename?: 'Week';
  friday: Array<WorkingHours>;
  monday: Array<WorkingHours>;
  saturday: Array<WorkingHours>;
  sunday: Array<WorkingHours>;
  thursday: Array<WorkingHours>;
  tuesday: Array<WorkingHours>;
  wednesday: Array<WorkingHours>;
};

export type WeekInput = {
  friday?: Array<WorkingHoursInput>;
  monday?: Array<WorkingHoursInput>;
  saturday?: Array<WorkingHoursInput>;
  sunday?: Array<WorkingHoursInput>;
  thursday?: Array<WorkingHoursInput>;
  tuesday?: Array<WorkingHoursInput>;
  wednesday?: Array<WorkingHoursInput>;
};

export enum WholesaleMeasureUnitProduct {
  Kilogramme = 'KILOGRAMME',
  Litre = 'LITRE',
  Unit = 'UNIT',
}

export type WorkingHours = {
  __typename?: 'WorkingHours';
  close: Scalars['DateTime'];
  open: Scalars['DateTime'];
};

export type WorkingHoursInput = {
  close: Scalars['DateTime'];
  open: Scalars['DateTime'];
};
