import { FC, useState, useCallback, useMemo } from 'react';
import TimeRange from './TimeRange';
import { Button } from '@material-ui/core';
import {
  OpenHourBox,
  WeekDaysGrid,
  OpenHourTitle,
  HoursListText,
  DialogContainer,
  Header,
  AppTimeRangeButtonBox,
  WeekDayColumn,
  Row,
} from './HoraryPicker.style';
import { ShopInformations } from '../../../graphQl/api_generated';
import { useRecoilValue } from 'recoil';
import {
  invalidDaysInTenantOpenHoursStore,
  invalidDaysInTenantPickupHoursStore,
} from '../../../store/tenant-errors.store';
import { dayName } from '../../../utils/enum';

const dayTranslations: any = {
  monday: 'lundi',
  tuesday: 'mardi',
  wednesday: 'mercredi',
  thursday: 'jeudi',
  friday: 'vendredi',
  saturday: 'samedi',
  sunday: 'dimanche',
};

interface HoraryPickerprops {
  headerTitle: string;
  shopDataState: [ShopInformations, (newValue: ShopInformations) => void];
  field: 'openHours' | 'pickupHours';
}

const HoraryPicker: FC<HoraryPickerprops> = (props) => {
  const { headerTitle, shopDataState, field }: HoraryPickerprops = props;

  const [shopData, setShopData] = shopDataState;
  const invalidDaysInTenantOpenHours = useRecoilValue(invalidDaysInTenantOpenHoursStore);
  const invalidDaysInTenantPickupHours = useRecoilValue(invalidDaysInTenantPickupHoursStore);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dayToUpdate, setDayToUpdate] = useState('');

  const openModal = useCallback(
    (day: string) => {
      setDayToUpdate(day);
      setIsModalOpen(true);
    },
    [setDayToUpdate, setIsModalOpen]
  );

  const weekDays = useMemo(
    () => (field === 'openHours' ? shopData.openHours.week : shopData.pickupHours.week),
    [field, shopData]
  );

  const addTimeRange = useCallback(() => {
    let updatedShop: ShopInformations = { ...shopData };

    field === 'openHours'
      ? weekDays[dayToUpdate as dayName].push({
          open: new Date(27000000),
          close: new Date(27000000),
        })
      : weekDays[dayToUpdate as dayName].push({
          open: new Date(27000000),
          close: new Date(27000000),
        });

    setShopData(updatedShop);
  }, [setShopData, dayToUpdate, field, shopData, weekDays]);

  const dayToUpdateOpenHours = useMemo(
    () => shopData[field].week[dayToUpdate as dayName] || [],
    [field, dayToUpdate, shopData]
  );

  return (
    <>
      <OpenHourBox>
        <OpenHourTitle>{headerTitle}</OpenHourTitle>
        <WeekDaysGrid>
          {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(
            (weekDay) => {
              const workingHours = weekDays[weekDay as dayName];

              const openHoursText = () => {
                if (workingHours.length <= 0) return 'Fermé';
                return workingHours.map((timetable, index) => {
                  const openDate = new Date(timetable.open);
                  const closedDate = new Date(timetable.close);

                  const formatDate = (date: Date) => {
                    return `${date.getUTCHours().toString().padStart(2, '0')} : ${date
                      .getUTCMinutes()
                      .toString()
                      .padStart(2, '0')}`;
                  };

                  return `${index !== 0 ? ' / ' : ''} ${formatDate(openDate)} - ${formatDate(
                    closedDate
                  )}`;
                });
              };

              const isFieldInvalid =
                field === 'openHours'
                  ? invalidDaysInTenantOpenHours.includes(weekDay as dayName)
                  : invalidDaysInTenantPickupHours.includes(weekDay as dayName);

              return (
                <Row key={weekDay}>
                  <WeekDayColumn>{dayTranslations[weekDay]}</WeekDayColumn>
                  <HoursListText onClick={() => openModal(weekDay)} isInvalid={isFieldInvalid}>
                    {openHoursText()}
                  </HoursListText>
                </Row>
              );
            }
          )}
        </WeekDaysGrid>
      </OpenHourBox>

      <DialogContainer
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={isModalOpen}
      >
        <Header>{dayTranslations[dayToUpdate]}</Header>

        {dayToUpdateOpenHours.map((_, index) => (
          <TimeRange
            key={index}
            index={index}
            shopDataState={shopDataState}
            field={field}
            dayToUpdate={dayToUpdate as dayName}
          />
        ))}

        {dayToUpdateOpenHours.length <= 2 && (
          <AppTimeRangeButtonBox>
            <Button variant="contained" onClick={() => addTimeRange()}>
              Ajouter une tranche horaire
            </Button>
          </AppTimeRangeButtonBox>
        )}
      </DialogContainer>
    </>
  );
};

export default HoraryPicker;
