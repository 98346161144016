import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { useMutation } from '@apollo/client';

import { Credentials, ShopInformations } from '../../../graphQl/api_generated';
import { CREATE_SHOP_INFORMATION, LOGIN, SIGNUP } from '../../../services/gqlHolder';
import { userInfo } from '../../../store';
import { setToken } from '../../../utils/userChecks';
import { defaultShopInformations } from '../../backoffice/TenantPage/common-data';
import {
  networkFormFields,
  shopFormFields,
  userFormFields,
} from '../../../components/SignupForm/fieldsData';
import SignupForm from '../../../components/SignupForm/SignupForm';
import { SignupPageContainer, StepperContainer, TopBox } from './SignupPage.style';
import { displayAlertSelector } from '../../../store/alert.store';

export default function SignupPage() {
  const displayAlert = useSetRecoilState(displayAlertSelector);
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [, setUser] = useRecoilState(userInfo);

  const handleNext = () => setActiveStep((prev) => prev + 1);

  const handlePrevious = () => setActiveStep((prev) => prev - 1);

  const [signup] = useMutation(SIGNUP, {
    onCompleted: () =>
      displayAlert({ severity: 'success', message: 'Votre compte a été crée avec succès.' }),
    onError: () =>
      displayAlert({
        severity: 'error',
        message: 'Impossible de créer votre compte, veuillez contacter votre revendeur.',
      }),
  });

  const [login] = useMutation(LOGIN);
  const sendSignup = (credentials: Credentials) => {
    signup({
      variables: {
        credentials,
      },
    })
      .then(() => {
        login({
          variables: {
            credentials,
          },
        })
          .then(({ data: { login } }) => {
            setToken(login.session);
            setUser(login.user);
            handleNext();
          })
          .catch(console.log);
      })
      .catch(console.log);
  };

  const [createShop] = useMutation(CREATE_SHOP_INFORMATION, {
    onCompleted: () =>
      displayAlert({ severity: 'success', message: 'Votre magasin a été crée avec succès.' }),

    onError: () =>
      displayAlert({
        severity: 'error',
        message: 'Impossible de créer votre magasin, veuillez contacter votre revendeur.',
      }),
  });

  const sendCreateShop = (shopInformations: ShopInformations) => {
    const { id, ownerId, slug, ...createdShop } = {
      ...defaultShopInformations,
      ...shopInformations,
    };
    createShop({
      variables: {
        shop: {
          ...createdShop,
        },
      },
    })
      .then(() => history.push('/'))
      .catch(console.log);
  };

  return (
    <SignupPageContainer>
      <TopBox>
        <Typography variant="h4" align="center">
          Creation de compte
        </Typography>
      </TopBox>
      <StepperContainer activeStep={activeStep} alternativeLabel>
        {['Informations utilisateur', 'Informations principales', 'Reseaux sociaux'].map(
          (label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          )
        )}
      </StepperContainer>
      {activeStep === 0 ? (
        <SignupForm handleNext={sendSignup} formFields={userFormFields} key="credentialsForm" />
      ) : activeStep === 1 ? (
        <SignupForm
          handleNext={handleNext}
          formFields={shopFormFields}
          key="shopInformationsForm"
        />
      ) : (
        <SignupForm
          handleNext={sendCreateShop}
          handlePrevious={handlePrevious}
          formFields={networkFormFields}
          key="shopInformationsForm"
        />
      )}
    </SignupPageContainer>
  );
}
