import { FC, useCallback } from 'react';
import ColorPicker from 'material-ui-color-picker';
import { ColorsGrid, ImagesUrlGrid } from './ThemeForm.style';
import { TenantSectionProps, InputField, SectionTitle } from '../common';
import { ShopInformations } from '../../../graphQl/api_generated';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { availableFonts } from '../../../theme';
import { defaultShopInformations } from '../../../modules/backoffice/TenantPage/common-data';

type colorFieldsName = 'primaryLighter' | 'primaryFontColor' | 'contrast' | 'primary';

type fieldsName =
  | 'headerDecorationUrl'
  | 'categoriesDecorationUrl'
  | 'backgroundDecorationUrl'
  | 'validationDecorationUrl'
  | 'logoUrl'
  | 'orderSummaryDecorationUrl'
  | 'cartDecorationUrl'
  | 'placeholderUrl'
  | 'primaryFont'
  | 'secondaryFont';

const colorFields: { name: colorFieldsName; label: string }[] = [
  { name: 'primary', label: 'Couleur primaire' },
  {
    name: 'primaryLighter',
    label: 'Couleur secondaire',
  },
  { name: 'primaryFontColor', label: 'Couleur police' },

  {
    name: 'contrast',
    label: 'Contraste',
  },
];

const fontFields: { name: fieldsName; label: string }[] = [
  { name: 'primaryFont', label: 'Police principale' },
  {
    name: 'secondaryFont',
    label: 'Police secondaire',
  },
];

const imagesFields: { name: fieldsName; label: string }[] = [
  { name: 'headerDecorationUrl', label: 'Entête' },
  { name: 'categoriesDecorationUrl', label: 'Catégories' },
  { name: 'backgroundDecorationUrl', label: 'Arrière plan' },
  { name: 'validationDecorationUrl', label: 'Récapitulatif commande' },
  { name: 'logoUrl', label: 'Logo' },
  { name: 'orderSummaryDecorationUrl', label: 'Écran de validation' },
  { name: 'cartDecorationUrl', label: 'Panier' },
  { name: 'placeholderUrl', label: 'Produit manquant' },
];

const ThemeForm: FC<TenantSectionProps> = (props) => {
  const { shopDataState }: TenantSectionProps = props;

  const [shopData, setShopData] = shopDataState;

  const updateShopState = useCallback(
    (fieldName: fieldsName | colorFieldsName, newValue: string) => {
      const newShopData: ShopInformations = { ...shopData };
      if (
        fieldName === 'primaryLighter' ||
        fieldName === 'primaryFontColor' ||
        fieldName === 'contrast' ||
        fieldName === 'primary'
      ) {
        newShopData.theme[fieldName] = newValue;
      } else {
        newShopData[fieldName] = newValue;
      }
      setShopData(newShopData);
    },
    [setShopData, shopData]
  );

  return (
    <div>
      <SectionTitle>Theme</SectionTitle>
      <ColorsGrid>
        {colorFields.map((field) => (
          <ColorPicker
            key={field.name}
            name={field.name}
            label={field.label}
            value={shopData.theme[field.name]}
            defaultValue={'Couleur'} // TODO: fix this
            onChange={(color) => updateShopState(field.name, color)}
          />
        ))}

        <div />

        {fontFields.map((field) => (
          <FormControl key={field.name} style={{ width: 'auto', minWidth: 100 }}>
            <InputLabel id={`${field.name}-select-label`}>{field.label}</InputLabel>

            <Select
              id="sellingMeasureUnit"
              value={shopData[field.name]}
              defaultValue={shopData[field.name] || defaultShopInformations[field.name]}
              onChange={(event) => updateShopState(field.name, event.target.value as string)}
            >
              {availableFonts.map((font, index) => {
                return (
                  <MenuItem key={index} value={font}>
                    {font}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ))}
      </ColorsGrid>

      <SectionTitle>Url d'images décoratives</SectionTitle>
      <ImagesUrlGrid>
        {imagesFields.map((field) => (
          <InputField
            key={field.name}
            name={field.name}
            label={field.label}
            value={shopData[field.name]}
            defaultValue={shopData[field.name]}
            onChange={(event) => updateShopState(field.name, event.target.value)}
          />
        ))}
      </ImagesUrlGrid>
    </div>
  );
};

export default ThemeForm;
